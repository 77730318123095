import React from 'react';
import { Grid, Button, Paper, Box } from '@material-ui/core/';
import { stylesGlobal } from './Styles';
import GraficoLinha from './GraficoLinha';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';

interface PainelGraficoProps {
    periodoLista: string[];
    setPeriodo: (periodo: string) => void;
    periodo: string;
    selectedDate: any;
    handleDateChange: (date: any, iniDate: boolean) => void;
    abaFiltro: any[];
    graph: any;
}

export default function PainelGrafico({
    periodoLista,
    setPeriodo,
    periodo,
    selectedDate,
    handleDateChange,
    abaFiltro,
    graph,
    ...props
}: PainelGraficoProps) {
    return (
        <Paper style={{ height: '100%' }}>
            <Box p={2} height="100%">
                <Grid container alignItems="center" style={{ height: '100%' }}>
                    <Grid item>
                        <h1 style={{ ...stylesGlobal.title, marginLeft: 10 }}>Quantidade por Período</h1>
                    </Grid>
                    <Grid item xs></Grid>

                    {periodoLista.map(p => {
                        return (
                            <Grid item key={p}>
                                <Button
                                    onClick={() => setPeriodo(p)}
                                    variant={periodo === p ? 'outlined' : 'contained'}
                                    style={periodo === p ? stylesGlobal.buttonPress : stylesGlobal.button}
                                >
                                    {p}
                                </Button>
                            </Grid>
                        );
                    })}

                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="dense"
                                id="date-picker-inline"
                                inputVariant="outlined"
                                label="Período de:"
                                value={selectedDate.iniDate}
                                onChange={e => handleDateChange(e, true)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                style={{ marginLeft: 10, width: '12vw' }}
                            />
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="dense"
                                inputVariant="outlined"
                                id="date-picker-inline"
                                label="até:"
                                value={selectedDate.lastDate}
                                onChange={e => handleDateChange(e, false)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                style={{ marginLeft: 10, width: '12vw' }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} style={{ height: '75%' }}>
                        <GraficoLinha abaFiltro={abaFiltro} data={graph} />
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
}
