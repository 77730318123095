import React from 'react';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';

import ReceivedOcurrences from '../../../../f5/ocurrences/ReceivedOcurrences';
import OcurrenceNavbar from '../../../../f5/ocurrences/SharedComponents/OcurrenceNavbar';

const Received = () => {
    return (
        <PageContainer>
            <OcurrenceNavbar />
            <ReceivedOcurrences />
        </PageContainer>
    );
};

export default Received;
