import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initializeOcurrencesArchived } from '../../../redux/actions/f5-actions/ocurrences-list-init';
import { Grid } from '@material-ui/core';
import GridContainer from '../../../@jumbo/components/GridContainer';

import SimpleDataCard from '../SharedComponents/SimpleDataCard';
import OcurrencesDataRender from '../SharedComponents/OcurrencesDataRender';

import FolderIcon from '@material-ui/icons/Folder';

const ArchivedOcurrences = () => {
  const [currentList, setCurrentList] = useState('archived');
  const archivedData = useSelector(({ ocurrences_data }) => ocurrences_data.archived.caixa);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeOcurrencesArchived());
  }, [dispatch]);

  const ocurrencesData = {
    archived: {
      icon: <FolderIcon fontSize="large" />,
      label: 'Ocorrências Arquivadas',
      colors: ['#4a99ba', '#0c5c7d'],
      data: archivedData,
    },
  };

  return (
    <GridContainer>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleDataCard
          label={ocurrencesData.archived.label}
          icon={ocurrencesData.archived.icon}
          colors={ocurrencesData.archived.colors}
          value={ocurrencesData.archived.data ? ocurrencesData.archived.data.length : 0}
          setList={() => setCurrentList('archived')}
        />
      </Grid>
      <Grid item xs={12}>
        <OcurrencesDataRender
          bgcolor={ocurrencesData[currentList].colors[0]}
          data={ocurrencesData[currentList].data}
          label={ocurrencesData[currentList].label}
          icon={ocurrencesData[currentList].icon}
        />
      </Grid>
    </GridContainer>
  );
};

export default ArchivedOcurrences;
