import React from 'react';
import {
    Paper,
    Box,
    DialogTitle,
    Dialog,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment';

const AttachmentDialog = ({ open, setOpen, files }) => {
    const headers = ['#', 'Ações', 'Criado em:', 'Observação'];
    const rows = files.map((item, pos) => {
        return { url: `/${item.url}`, note: item.observacao, id: item.id, pos: pos + 1, date: moment(item.created_at).format('HH:mm DD/MM/YYYY') };
    });

    const onDowload = id => {
        let token = localStorage.getItem('token');
        fetch(`https://capeladoalto.cidadeinteligente.app.br/api/ouvidoria/ocorrencia/anexo/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(r => r.blob())
            .then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.target = '_blank';
                a.click();
            });
    };

    return (
        <Dialog onClose={() => setOpen(false)} open={open} fullWidth maxWidth={'md'}>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <AttachFileIcon />
                    <Box ml={2}>Lista de Anexos</Box>
                </Box>
            </DialogTitle>
            <Box px={8} mb={4}>
                <TableContainer component={Paper}>
                    <Table aria-label="histórico da ocorrência">
                        <TableHead>
                            <TableRow>
                                {headers.map((item, index) => {
                                    return (
                                        <TableCell key={index}>
                                            <Box fontWeight={700}>{item}</Box>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{item.pos}</TableCell>
                                        <TableCell>
                                            <div onClick={() => onDowload(item.id)} style={{ cursor: 'pointer' }}>
                                                <GetAppIcon />
                                            </div>
                                        </TableCell>
                                        <TableCell>{item.date}</TableCell>
                                        <TableCell>{item.note}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Dialog>
    );
};

export default AttachmentDialog;
