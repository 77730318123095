import { Grid, List, ListItem, ListItemText } from '@material-ui/core/';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import React, { useState } from 'react';

export default function PerguntaLinha({ ...props }) {
  const data = [{ name: 'A', x: 12, y: 23, z: 122 }];
  const [isHover, setIsHover] = useState(false);

  return (
    <div style={{ width: '100%' }}>
      <Grid
        onClick={() => {
          let perguntas = [...props.lista];
          perguntas[props.pos].ativo = !perguntas[props.pos].ativo;
          props.onClick(perguntas);
        }}
        container
        direction="row"
        onMouseEnter={() => {
          setIsHover(true);
        }}
        onMouseLeave={() => {
          setIsHover(false);
        }}
        style={
          isHover
            ? props.ativo
              ? { backgroundColor: '#256DBE', paddingLeft: 10 }
              : { backgroundColor: '#82C1BE', paddingLeft: 10 }
            : props.ativo
            ? { backgroundColor: '#2596BE', paddingLeft: 10 }
            : { backgroundColor: 'white', paddingLeft: 10 }
        }>
        <Grid item xs={8}>
          <List component="nav" aria-labelledby="nested-list-subheader">
            <ListItem key={props.texto} dense>
              <ListItemText style={{ cursor: 'pointer' }} primary={props.perguntaTexto} />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={4}>
          <ResponsiveContainer>
            <BarChart key={props.texto} layout="vertical" data={data}>
              <XAxis hide={true} type="number" />
              <YAxis hide={true} type="category" />
              <Bar dataKey="y" stackId="a" fill="green" />
              <Bar dataKey="x" stackId="a" fill="red" />
            </BarChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </div>
  );
}
