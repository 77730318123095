import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initializeOcurrencesReceived, initializeOcurrencesNew, initializeOcurrencesReturned, initializeOcurrencesPending } from '../../../redux/actions/f5-actions/ocurrences-list-init';
import { Grid } from '@material-ui/core';
import GridContainer from '../../../@jumbo/components/GridContainer';
import { useLocation } from 'react-router-dom';
import SimpleDataCard from '../SharedComponents/SimpleDataCard';
import OcurrencesDataRender from '../SharedComponents/OcurrencesDataRender';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import ReplayIcon from '@material-ui/icons/Replay';

const ReceivedOcurrences = () => {
    const userAccess = useSelector(({ auth }) => (auth.authUser ? auth.authUser.cargo.interno : null));
    const [currentList, setCurrentList] = useState('received');
    const receivedData = useSelector(({ ocurrences_data }) => ocurrences_data.received.caixa);
    const newData = useSelector(({ ocurrences_data }) => ocurrences_data.new.caixa);
    const returnedData = useSelector(({ ocurrences_data }) => ocurrences_data.returned.caixa);
    const pendingData = useSelector(({ ocurrences_data }) => ocurrences_data.pending.caixa);
    const dispatch = useDispatch();
    const params = useLocation();

    useEffect(() => {
        if (params.search === '?status=estornada') {
            setCurrentList('returned');
        } else {
            setCurrentList('received');
        }
    }, [params]);

    useEffect(() => {
        dispatch(initializeOcurrencesReceived());
        dispatch(initializeOcurrencesNew());
        dispatch(initializeOcurrencesReturned());
        dispatch(initializeOcurrencesPending());
    }, [dispatch]);

    const ocurrencesData = {
        received: {
            icon: <FileCopyIcon fontSize="large" />,
            label: 'Ocorrências Recebidas',
            uniqueIdentifier: 'received',
            colors: ['#2f6bcc', '#042d80'],
            data: userAccess !== 'gestao' && userAccess !== 'funcionario' ? receivedData : pendingData, // Embora mostre como recebida, na perspectiva do departamento será "pendente"
        },
        new: {
            icon: <CreateNewFolderIcon fontSize="large" />,
            label: 'Ocorrências Novas',
            uniqueIdentifier: 'new',
            colors: ['#42bded', '#0473ab'],
            data: newData,
        },
        returned: {
            icon: <ReplayIcon fontSize="large" />,
            label: 'Ocorrências Estornadas',
            uniqueIdentifier: 'returned',
            colors: ['#b3ae32', '#ab8a04'],
            data: returnedData,
        },
    };

    return (
        <GridContainer>
            <Grid item xs={12} sm={6} md={3}>
                <SimpleDataCard
                    label={ocurrencesData.received.label}
                    icon={ocurrencesData.received.icon}
                    colors={ocurrencesData.received.colors}
                    value={ocurrencesData.received.data ? ocurrencesData.received.data.length : 0}
                    setList={() => setCurrentList('received')}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <SimpleDataCard label={ocurrencesData.new.label} icon={ocurrencesData.new.icon} colors={ocurrencesData.new.colors} value={ocurrencesData.new.data ? ocurrencesData.new.data.length : 0} setList={() => setCurrentList('new')} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                {userAccess === 'ouvidor' && (
                    <SimpleDataCard
                        label={ocurrencesData.returned.label}
                        icon={ocurrencesData.returned.icon}
                        colors={ocurrencesData.returned.colors}
                        value={ocurrencesData.returned.data ? ocurrencesData.returned.data.length : 0}
                        setList={() => setCurrentList('returned')}
                    />
                )}
            </Grid>

            <Grid item xs={12}>
                <OcurrencesDataRender bgcolor={ocurrencesData[currentList].colors[0]} data={ocurrencesData[currentList].data} label={ocurrencesData[currentList].label} icon={ocurrencesData[currentList].icon} />
            </Grid>
        </GridContainer>
    );
};

export default ReceivedOcurrences;
