import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';

import GeneralOcurrences from '../../../../f5/ocurrences/GeneralOcurrences';
import OcurrenceNavbar from '../../../../f5/ocurrences/SharedComponents/OcurrenceNavbar';
import { useMainContext } from '../../../../redux/MainContext';

const Ocurrences = () => {
    const userAccess = useSelector(({ auth }) => (auth.authUser ? auth.authUser.cargo.interno : null));
    const [subDeps, setSubDeps] = useState([]);
    const [renderSubsLoader, setRenderSubsLoader] = useState(false);
    const { getData } = useMainContext();

    useEffect(() => {
        const getSubDeps = async () => {
            if (['gestao', 'funcionario', 'departamento', 'sub_departamento', 'ouv_master'].includes(userAccess)) {
                setRenderSubsLoader(true);
                const dashboardData = await getData('dashboard');

                setSubDeps(dashboardData.subdepartamento.length > 1 ? dashboardData.subdepartamento.slice(1) : []);
                setRenderSubsLoader(false);
            }
        };

        getSubDeps();
    }, [userAccess, setSubDeps, setRenderSubsLoader, getData]);

    return (
        <PageContainer>
            {userAccess === 'ouv_master' ? null : <OcurrenceNavbar />}
            <GeneralOcurrences renderSubs={subDeps} renderSubsLoader={renderSubsLoader} />
        </PageContainer>
    );
};

export default Ocurrences;
