import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initializeOcurrencesCompleted } from '../../../redux/actions/f5-actions/ocurrences-list-init';
import { Box, Grid } from '@material-ui/core';
import GridContainer from '../../../@jumbo/components/GridContainer';

import SimpleDataCard from '../SharedComponents/SimpleDataCard';
import OcurrencesDataRender from '../SharedComponents/OcurrencesDataRender';

import FolderIcon from '@material-ui/icons/Folder';

const CompletedOcurrences = () => {
  const [currentList, setCurrentList] = useState('completed');
  const completedData = useSelector(({ ocurrences_data }) => ocurrences_data.completed.caixa);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeOcurrencesCompleted());
  }, [dispatch]);

  const ocurrencesData = {
    completed: {
      icon: <FolderIcon fontSize="large" />,
      label: 'Ocorrências Finalizadas',
      colors: ['#4a99ba', '#0c5c7d'],
      data: completedData,
    },
  };

  return (
    <GridContainer>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleDataCard
          label={ocurrencesData.completed.label}
          icon={ocurrencesData.completed.icon}
          colors={ocurrencesData.completed.colors}
          value={ocurrencesData.completed.data ? ocurrencesData.completed.data.length : 0}
          setList={() => setCurrentList('completed')}
        />
      </Grid>
      <Grid item xs={12}>
        <OcurrencesDataRender
          bgcolor={ocurrencesData[currentList].colors[0]}
          data={ocurrencesData[currentList].data}
          label={ocurrencesData[currentList].label}
          icon={ocurrencesData[currentList].icon}
          extraColumns={[
            {
              title: 'Tempo de resposta',
              field: 'tempo_resposta',
              render: rowData => (
                <Box fontSize={12}>{rowData.tempo_resposta <= 0 ? '1' : rowData.tempo_resposta} Dia(s)</Box>
              ),
            },
          ]}
        />
      </Grid>
    </GridContainer>
  );
};

export default CompletedOcurrences;
