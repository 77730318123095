import {
    INIT_OCURRENCES_ALL,
    INIT_OCURRENCES_RECEIVED,
    INIT_OCURRENCES_NEW,
    INIT_OCURRENCES_RETURNED,
    INIT_OCURRENCES_PENDING,
    INIT_OCURRENCES_ONGOING,
    INIT_OCURRENCES_ONTIME,
    INIT_OCURRENCES_DUE,
    INIT_OCURRENCES_OVERDUE,
    INIT_OCURRENCES_COMPLETED,
    INIT_OCURRENCES_SOLVED,
    INIT_OCURRENCES_APPEALED,
    INIT_OCURRENCES_EXTEND,
    INIT_OCURRENCES_EXTEND_ACCEPTED,
    INIT_OCURRENCES_EXTEND_REJECTED,
    INIT_OCURRENCES_ARCHIVED,
    INIT_OCURRENCES_SERVICES,
} from '../../actions/f5-actions/action-types';

const INIT_STATE = {
    received: {},
    new: {},
    returned: {},
    pending: {},
    ongoing: {},
    ontime: {},
    due: {},
    overdue: {},
    completed: {},
    archived: {},
    services: {},
    solved: {},
    appealed: {},
    extend: {},
    extend_accepted: {},
    extend_rejected: {},
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case INIT_OCURRENCES_ALL: {
            return {
                ...state,
                received: { ...action.received },
                new: { ...action.new },
                returned: { ...action.returned },
                pending: { ...action.pending },
                ongoing: { ...action.ongoing },
                ontime: { ...action.ontime },
                due: { ...action.due },
                overdue: { ...action.overdue },
                completed: { ...action.completed },
                archived: { ...action.archived },
                services: { ...action.services },
                solved: { ...action.solved },
                appealed: { ...action.appealed },
                extend: { ...action.extend },
                extend_accepted: { ...action.extend_accepted },
                extend_rejected: { ...action.extend_rejected },
            };
        }
        case INIT_OCURRENCES_RECEIVED: {
            return { ...state, received: action.data };
        }
        case INIT_OCURRENCES_NEW: {
            return { ...state, new: action.data };
        }
        case INIT_OCURRENCES_RETURNED: {
            return { ...state, returned: action.data };
        }
        case INIT_OCURRENCES_PENDING: {
            return { ...state, pending: action.data };
        }
        case INIT_OCURRENCES_ONGOING: {
            return { ...state, ongoing: action.data };
        }
        case INIT_OCURRENCES_ONTIME: {
            return { ...state, ontime: action.data };
        }
        case INIT_OCURRENCES_DUE: {
            return { ...state, due: action.data };
        }
        case INIT_OCURRENCES_OVERDUE: {
            return { ...state, overdue: action.data };
        }
        case INIT_OCURRENCES_COMPLETED: {
            return { ...state, completed: action.data };
        }
        case INIT_OCURRENCES_ARCHIVED: {
            return { ...state, archived: action.data };
        }
        case INIT_OCURRENCES_SERVICES: {
            return { ...state, services: action.data };
        }
        case INIT_OCURRENCES_SOLVED: {
            return { ...state, solved: action.data };
        }
        case INIT_OCURRENCES_APPEALED: {
            return { ...state, appealed: action.data };
        }
        case INIT_OCURRENCES_EXTEND: {
            return { ...state, extend: action.data };
        }
        case INIT_OCURRENCES_EXTEND_ACCEPTED: {
            return { ...state, extend_accepted: action.data };
        }
        case INIT_OCURRENCES_EXTEND_REJECTED: {
            return { ...state, extend_rejected: action.data };
        }
        default:
            return state;
    }
};
