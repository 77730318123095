import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
// import IntlMessages from '../../../utils/IntlMessages';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import { alpha, makeStyles } from '@material-ui/core/styles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import PersonIcon from '@material-ui/icons/Person';
import ReCAPTCHA from 'react-google-recaptcha';
import { cleanDepartmentOmbudsman } from '../../../../redux/actions/f5-actions/department-ombudsman';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginLeft: '0.5rem',
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
}));
//variant = 'default', 'standard'
const SignIn = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [captcha, setCaptcha] = useState(null);
  const dispatch = useDispatch();
  const classes = useStyles({ variant });

  useEffect(() => {
    dispatch(cleanDepartmentOmbudsman());
  }, [dispatch]);

  const onSubmit = () => {
    if (captcha) {
      dispatch(AuhMethods[method].onLogin({ email, password }));
    }
  };

  const handleCaptcha = value => {
    setCaptcha(value);
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box className={classes.authThumb}>
          <CmtImage src={'images/auth/login-img.png'} />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box display="flex" alignItems="center" width="100%">
          <Box width="50%">
            <Box display="flex" alignItems="center" mb={8}>
              <PersonIcon fontSize="large" />
              <Typography component="div" variant="h1" className={classes.titleRoot}>
                Autenticação
              </Typography>
            </Box>
            <form>
              <Box mb={2}>
                <TextField
                  label={'Email'}
                  fullWidth
                  onChange={event => setEmail(event.target.value)}
                  defaultValue={email}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  type="password"
                  label={'Senha'}
                  fullWidth
                  onChange={event => setPassword(event.target.value)}
                  defaultValue={password}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>

              <Box width="100%" mb={2}>
                <ReCAPTCHA size="normal" sitekey="6LdhP64eAAAAAJsWGOBcZbnZEqoq1eqMkmj-HnBB" onChange={handleCaptcha} />
              </Box>

              <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
                <Button onClick={onSubmit} variant="contained" color="primary">
                  Entrar
                </Button>
              </Box>
            </form>
          </Box>
          <Box mb={7} mx="auto">
            <CmtImage src={'images/f5-assets/ci-logo.png'} alt="Cidade Inteligente Logotipo" width={200} />
          </Box>
        </Box>

        {dispatch(AuhMethods[method].getSocialMediaIcons())}

        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default SignIn;
