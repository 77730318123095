import axios from '../auth/jwt/config';

const getOcurrencesCount = async () => {
    const response = await axios.get('ouvidoria/contador');
    return response.data;
};

const getReceivedOcurrences = async () => {
    const response = await axios.get('ouvidoria/caixa/entrada');
    return response.data;
};

const getNewOcurrences = async () => {
    const response = await axios.get('ouvidoria/caixa/novas');
    return response.data;
};

const getReturnedOcurrences = async () => {
    const response = await axios.get('ouvidoria/caixa/estornadas');
    return response.data;
};

const getPendingOcurrences = async () => {
    const response = await axios.get('ouvidoria/caixa/pendente');
    return response.data;
};

const getOngoingOcurrences = async () => {
    const response = await axios.get('ouvidoria/caixa/andamento');
    return response.data;
};

const getOntimeOcurrences = async () => {
    const response = await axios.get('ouvidoria/caixa/dentroPrazo');
    return response.data;
};

const getDueOcurrences = async () => {
    const response = await axios.get('ouvidoria/caixa/aVencer');
    return response.data;
};

const getOverdueOcurrences = async () => {
    const response = await axios.get('ouvidoria/caixa/vencidas');
    return response.data;
};

const getCompletedOcurrences = async () => {
    const response = await axios.get('ouvidoria/caixa/finalizada');
    return response.data;
};

const getArchivedOcurrences = async () => {
    const response = await axios.get('ouvidoria/caixa/archived');
    return response.data;
};

const getServicesOcurrences = async () => {
    const response = await axios.get('ouvidoria/caixa/services');
    return response.data;
};

const getSolvedOcurrences = async () => {
    const response = await axios.get('ouvidoria/caixa/solucionada');
    return response.data;
};

const getAppealedOcurrences = async () => {
    const response = await axios.get('ouvidoria/caixa/recurso');
    return response.data;
};

const getExtendOcurrences = async () => {
    const response = await axios.get('ouvidoria/caixa/prorrogacao');
    return response.data;
};

const getExtendAcceptedOcurrences = async () => {
    const response = await axios.get('ouvidoria/caixa/prorrogacaoAtiva');
    return response.data;
};

const getExtendRejectedOcurrences = async () => {
    const response = await axios.get('ouvidoria/caixa/prorrogacaoRejeitada');
    return response.data;
};

export default {
    getReceivedOcurrences,
    getNewOcurrences,
    getReturnedOcurrences,
    getPendingOcurrences,
    getOngoingOcurrences,
    getOntimeOcurrences,
    getDueOcurrences,
    getOverdueOcurrences,
    getCompletedOcurrences,
    getSolvedOcurrences,
    getOcurrencesCount,
    getAppealedOcurrences,
    getExtendOcurrences,
    getExtendAcceptedOcurrences,
    getExtendRejectedOcurrences,
    getArchivedOcurrences,
    getServicesOcurrences,
};
