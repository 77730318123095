import React from 'react';
import { Grid, Button, Paper, Box } from '@material-ui/core';
import { stylesGlobal } from './Styles';
import AbaFiltro from './AbaFiltro';
import AddIcon from '@material-ui/icons/Add';
import { Filtro } from '../../../routes/Pages/Dashboard/RatingsDashboard';

interface PainelFiltrosProps {
    addFiltro: () => void;
    removerFiltro: (id: number) => void;
    abaFiltro: any[];
    onSelectAbaFiltro: (id: number) => void;
    filtro: Filtro;
    selectedFiltro: number;
    onUpdateFiltro: (values: any[]) => void;
}

export default function PainelFiltros({
    addFiltro,
    removerFiltro,
    abaFiltro,
    onSelectAbaFiltro,
    filtro,
    selectedFiltro,
    onUpdateFiltro,
    ...props
}: PainelFiltrosProps) {
    const onChange = (type: any, value: any) => {
        let hold = [...abaFiltro];
        hold[selectedFiltro].values[type] = value;
        onUpdateFiltro(hold);
    };

    return (
        <Paper style={{ height: '100%' }}>
            <Box p={3}>
                <Grid container spacing={2} direction={'row'}>
                    <Grid item>
                        {abaFiltro.map((e, pos) => (
                            <Button
                                key={pos}
                                variant={pos === selectedFiltro ? 'outlined' : 'contained'}
                                style={pos === selectedFiltro ? stylesGlobal.buttonPress : stylesGlobal.button}
                                onClick={() => {
                                    onSelectAbaFiltro(pos);
                                }}
                            >
                                {e.nome}
                            </Button>
                        ))}
                    </Grid>
                    <Grid item>
                        <Button style={{ margin: 3 }} variant={'outlined'} onClick={addFiltro}>
                            <AddIcon />
                        </Button>
                    </Grid>
                </Grid>
                <AbaFiltro
                    filtro={filtro}
                    values={abaFiltro[selectedFiltro].values}
                    onChange={onChange}
                    id={selectedFiltro}
                    removerFiltro={removerFiltro}
                />
            </Box>
        </Paper>
    );
}
