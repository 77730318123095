import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

let colors = ['#ac1fc4', '#29cc66', '#3248a8', '#ff1000', '#ff9100', '#eeff00', '#6eff00', '#00ff11'];

interface GraficoLinhaProps {
    abaFiltro: any[];
    data: any[];
}

export default function GraficoLinha({ abaFiltro, data, ...props }: GraficoLinhaProps) {
    return (
        <ResponsiveContainer width={'100%'} height={'100%'}>
            <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                {abaFiltro.map((item, pos) => (
                    <Line
                        type="monotone"
                        label={item.nome}
                        name={item.nome}
                        dataKey={`value${pos}`}
                        stroke={colors[pos % colors.length]}
                    />
                ))}
            </LineChart>
        </ResponsiveContainer>
    );
}
