import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#ed213d', '#b070d6'];

export default function VencidaBarAndTable({ data, title = '', ...props }) {
    if (!data) return null;
    return (
        <Box mt={2}>
            <h3>{title}</h3>
            <Grid container spacing={1} alignItems="center" justifyContent="center">
                <Grid item xs={6}>
                    <ResponsiveContainer width="100%" height={500}>
                        <BarChart width={200} height={200} data={data} layout="vertical">
                            <Tooltip />
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis type="number" />
                            <YAxis dataKey="dias" type="category" width={200} />
                            <Bar dataKey="ocorrencias">
                                {data.map((_entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </Grid>
                <Grid item xs={6}>
                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Dias após prazo</TableCell>
                                    <TableCell align="right">Quantidade</TableCell>
                                    <TableCell>Protocolos</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map(row => (
                                    <TableRow key={row.nome}>
                                        <TableCell component="th" scope="row">
                                            {row.dias} Dias
                                        </TableCell>
                                        <TableCell align="right">{row.ocorrencias} Ocorrências</TableCell>
                                        <TableCell align="right">
                                            {row.protocolo.map(proto => {
                                                return `${proto},`;
                                            })}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <b>Total</b>
                                    </TableCell>
                                    <TableCell align="right">
                                        <b>{data.reduce((partial, item) => partial + item.ocorrencias, 0)}</b>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
}
