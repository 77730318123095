import React from 'react';
import { Paper, Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const useStyles = makeStyles(theme => ({
  paperRoot: { height: '100%' },
  cardRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  destinyTag: {
    padding: '0.25rem 0.5rem',
    backgroundColor: 'orange',
    borderRadius: 10,
  },
}));

const RedirectionCard = ({ movement }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paperRoot}>
      <Box className={classes.cardRoot} py={2} px={4}>
        <Box>
          <Box display="flex" alignItems="center" my={2}>
            <ArrowRightAltIcon fontSize="small" />
            <Box ml={2} fontWeight={500}>
              Encaminhamento
            </Box>
          </Box>
          <Box mb={2}>
            <Divider />
          </Box>
        </Box>
        <Box mb={2} display="flex" alignItems="center">
          <Box fontWeight={700} fontSize={14}>
            Destino:
          </Box>
          <Box ml={2} className={classes.destinyTag}>
            {movement.destinatario}
          </Box>
        </Box>
        <Box mb={2} display="flex" alignItems="center">
          <Box fontWeight={700} fontSize={14}>
            Descrição:
          </Box>
          <Box ml={2}>{movement.descricao}</Box>
        </Box>
        <Box mb={2} display="flex" alignItems="center">
          <Box fontWeight={700} fontSize={14}>
            Observação interna:
          </Box>
          <Box ml={2}>{movement.observacao}</Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default RedirectionCard;
