import { Box, Grid, Paper } from '@material-ui/core/';
import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { stylesGlobal } from './Styles';
import PerguntaLinha from './PerguntaLinha';
import { CityCenter } from '../../constants/MapData';

interface PainelMapaProps {
    markers: Array<
        Array<{
            lat: number;
            lng: number;
        }>
    >;
    servicos?: Array<{
        id: number;
        nome: string;
        ativo: boolean;
    }>;
    selected: number;
}

export default function PainelMapa({ markers, servicos = [], selected }: PainelMapaProps) {
    const [abaPainel, setAbaPainel] = useState('Mapa');

    return (
        <Paper>
            <Box p={3}>
                <Grid container direction="row">
                    <Grid item>
                        <Button
                            variant={abaPainel === 'Mapa' ? 'outlined' : 'contained'}
                            style={abaPainel === 'Mapa' ? stylesGlobal.buttonPress : stylesGlobal.button}
                            onClick={() => setAbaPainel('Mapa')}
                        >
                            Mapa
                        </Button>
                    </Grid>
                    {/* <Grid item>
            <Button
              variant={abaPainel === 'Servicos' ? 'outlined' : 'contained'}
              style={abaPainel === 'Servicos' ? stylesGlobal.buttonPress : stylesGlobal.button}
              onClick={() => setAbaPainel('Servicos')}>
              Servicos
            </Button>
          </Grid> */}
                    <Grid item xs={12}>
                        {abaPainel === 'Mapa' ? (
                            <GoogleMap
                                mapContainerStyle={{ width: '100%', height: '50vh' }}
                                center={CityCenter}
                                zoom={15}
                                options={{ mapTypeControl: false, fullscreenControl: false, streetViewControl: false }}
                            >
                                {markers && markers.length >= selected + 1 && selected >= 0
                                    ? markers[selected].map((e, pos) => {
                                          return (
                                              <Marker
                                                  key={pos}
                                                  position={{ lat: e.lat, lng: e.lng }}
                                                  icon={require('../assets/marcador.png')}
                                                  visible
                                              />
                                          );
                                      })
                                    : null}
                            </GoogleMap>
                        ) : (
                            servicos.map((e, pos) => {
                                return (
                                    <Grid key={e.id} item xs={12}>
                                        <PerguntaLinha lista={servicos} pos={pos} perguntaTexto={e.nome} ativo={e.ativo} />
                                    </Grid>
                                );
                            })
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
}
