import { Box, Button, CircularProgress, TextField, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import FeedbackAlert from './FeedbackAlert';
import SharedDescription from './SharedDescription';
import SharedTitle from './SharedTitle';
import RestorePageIcon from '@material-ui/icons/RestorePage';
import axios_instance from '../../../../services/auth/jwt/config';
import { useDispatch } from 'react-redux';
import sweetAlert from '../../../utils/sweetAlert';
import { selectOcurrence } from '../../../../redux/actions/f5-actions/ocurrences-actions';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  saveButton: {
    color: 'white',
    backgroundColor: '#21cc37',
    '&:hover': {
      backgroundColor: '#32e33b',
    },
  },
}));

export default function EstornarOcurrence({ ocurrence, closeIt }) {
  const [loading, setLoading] = useState({ message: '', status: false });
  const [alertOpen, setAlertOpen] = useState(false);
  const [justificativa, setJustificativa] = useState('');
  const dispatch = useDispatch();

  const classes = useStyles();

  const handleSubmit = () => {
    setLoading({ message: 'Estornando ocorrência...', status: true });
    let form = {
      observacao: justificativa,
    }
    axios_instance.post(`ouvidoria/ocorrencia/estornar/${ocurrence.ocorrencia_id}`, form).then((response) => {
      dispatch(selectOcurrence(ocurrence.ocorrencia_id));
      sweetAlert('success', 'Sucesso', 'Ocorrência estornada com sucesso', () => {
        setLoading({ status: false, message: '' });
      });
    }).catch(() => {
      sweetAlert('error', 'Erro', 'Erro ao estornar ocorrência', () => {
        setLoading({ status: false, message: '' });
      });

    })
  }

  if (loading.status) {
    return (
      <Box
        className={classes.root}
        height={500}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center">
        <CircularProgress />
        <Box mt={4} fontWeight={700}>
          {loading.message}
        </Box>
      </Box>
    );
  }
  return <div className={classes.root}>
    <SharedTitle icon={<RestorePageIcon />} title="Estornar Ocorrência" />
    <SharedDescription
      requester={ocurrence.solicitante.nome}
      date={`${new Date(ocurrence.abertura).toLocaleDateString()} às ${new Date(ocurrence.abertura).toLocaleTimeString()}`}
      service={ocurrence.servico}
      protocol={ocurrence.protocolo}
    />

    <Box width="100%" mb={4}>
      <TextField
        label="Justificativa"
        required
        multiline
        minRows={3}
        variant="outlined"
        fullWidth
        value={justificativa}
        onChange={e => setJustificativa(e.target.value)}
      />
    </Box>
    <Box mt={2} px={4} display="flex" justifyContent="end">
      <Button classes={{ root: classes.saveButton }} onClick={handleSubmit}>
        Estornar
      </Button>
    </Box>
    <FeedbackAlert message="Por favor, preencha os campos obrigatórios." open={alertOpen} setOpen={setAlertOpen} />
  </div>
}