import React, { useState } from 'react';
import { config } from '../../../services/auth/jwt/config';
import { Paper, Box, Divider, Button, IconButton, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AttachmentDialog from './AttachmentDialog';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { CloudDownload } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import AttachmentDisplay from './AttachmentDisplay';

const useStyles = makeStyles(theme => ({
    paperRoot: { height: '100%' },
    cardRoot: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    imgStyle: {
        width: '95%',
        height: '250px',
        objectFit: 'contain',
    },
    fileControl: {
        position: 'absolute',
        bottom: 0,
        width: '95%',
        height: '32px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    controlColor: {
        color: 'white',
    },
    iconButtonRoot: {
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.15)',
        },
    },
}));

const AttachmentCard = ({ files }) => {
    const [openFileList, setOpenFileList] = useState(false);
    const [targetFile, setTargetFile] = useState(0);
    const [downloading, setDownloading] = useState(false);
    const classes = useStyles();

    const notVisual = () => {
        return files.filter(item => !item.type);
    };

    const onDowload = id => {
        setDownloading(true);
        let token = localStorage.getItem('token');
        fetch(`https://capeladoalto.cidadeinteligente.app.br/api/ouvidoria/ocorrencia/anexo/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(r => r.blob())
            .then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.target = '_blank';
                a.click();
                setDownloading(false);
            })
            .catch(err => {
                setDownloading(false);
            });
    };

    const nextControl = () => {
        if (targetFile < files.length - 1) {
            setTargetFile(targetFile + 1);
        }
    };

    const previousControl = () => {
        if (targetFile > 0) {
            setTargetFile(targetFile - 1);
        }
    };

    const fileVizualizationControl = () => {
        return (
            <Box className={classes.fileControl} display="flex" alignItems="center" justifyContent="center">
                <IconButton
                    aria-label="ver proximo anexo"
                    size="small"
                    classes={{ root: classes.iconButtonRoot }}
                    disabled={targetFile === 0}
                    onClick={previousControl}
                >
                    <ArrowBackIcon fontSize="small" className={classes.controlColor} />
                </IconButton>
                <Box mx={4} className={classes.controlColor} fontWeight={700} fontSize={12}>
                    {files.length > 0 ? targetFile + 1 : 0} / {files.length}
                </Box>
                <IconButton
                    aria-label="ver anexo anterior"
                    size="small"
                    classes={{ root: classes.iconButtonRoot }}
                    disabled={files.length === targetFile + 1}
                    onClick={nextControl}
                >
                    <ArrowForwardIcon fontSize="small" className={classes.controlColor} />
                </IconButton>
            </Box>
        );
    };

    return (
        <Paper className={classes.paperRoot}>
            {downloading && (
                <Alert severity="info">
                    <CircularProgress size={20} />
                    Baixando arquivo...
                </Alert>
            )}
            <Box className={classes.cardRoot} py={2} px={4}>
                <Box>
                    <Box display="flex" alignItems="center" my={2}>
                        <InsertDriveFileIcon fontSize="small" />
                        <Box ml={2} fontWeight={500}>
                            Anexos
                        </Box>
                    </Box>
                    <Box mb={2}>
                        <Divider />
                    </Box>
                </Box>
                <Box display="flex" justifyContent="center" mb={2} position="relative" minHeight={35}>
                    {files.map((file, pos) => (
                        <AttachmentDisplay onDowload={onDowload} item={file} key={pos} display={targetFile === pos} />
                    ))}
                    {fileVizualizationControl()}
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center">
                    {notVisual.length <= 0 ? (
                        <React.Fragment />
                    ) : (
                        <Box textAlign="center" px={4} mb={2} fontStyle="italic">
                            Há anexos que não puderam ser exibidos.
                        </Box>
                    )}
                    <Button variant="outlined" onClick={() => setOpenFileList(true)}>
                        Abrir lista de anexos
                    </Button>
                    <AttachmentDialog open={openFileList} setOpen={setOpenFileList} files={files} />
                </Box>
            </Box>
        </Paper>
    );
};

export default AttachmentCard;
