import React from 'react';
import { Box, Button, Grid, Slider } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { stylesGlobal } from './Styles';
import { Filtro, ValueFiltro } from '../../../routes/Pages/Dashboard/RatingsDashboard';

interface AbaFiltroProps {
    filtro: Filtro;
    id: number;
    removerFiltro: (id: number) => void;
    values: ValueFiltro;
    onChange: (key: string, value: any) => void;
}

export default function AbaFiltro({ filtro, id, removerFiltro, values, onChange, ...props }: AbaFiltroProps) {
    return (
        <Box px={2}>
            <Grid container spacing={2}>
                <Grid item xs={8}></Grid>
                <Grid item xs={4}>
                    <Button variant={'outlined'} onClick={() => removerFiltro(id)}>
                        <DeleteForeverIcon />
                        Remover Filtro
                    </Button>
                </Grid>

                <Grid item xs={12} style={{ marginTop: 20 }}>
                    <div style={stylesGlobal.title}>Faixa Etária</div>
                    <Slider
                        value={values?.faixaEtaria ?? [0, 120]}
                        onChange={(_event, value) => {
                            onChange('faixaEtaria', value);
                        }}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                    />
                </Grid>
                <Grid item xs={12}>
                    <div style={stylesGlobal.title}>Sexo</div>
                    {filtro.sexo.map(subItem => {
                        return (
                            <Button
                                variant={values?.sexo?.includes(subItem.id) ? 'contained' : 'outlined'}
                                onClick={() => {
                                    let hold = [...(values?.sexo ?? [])];
                                    if (hold.includes(subItem.id)) {
                                        hold = hold.filter(e => e !== subItem.id);
                                    } else {
                                        hold.push(subItem.id);
                                    }
                                    onChange('sexo', hold);
                                }}
                                key={subItem.nome}
                                style={values?.sexo?.includes(subItem.id) ? stylesGlobal.buttonPress : stylesGlobal.button}
                            >
                                {subItem.nome}
                            </Button>
                        );
                    })}
                </Grid>
                <Grid item xs={12}>
                    <div style={stylesGlobal.title}>Gravidade</div>
                    {filtro.gravidade.map(item => {
                        return (
                            <Button
                                variant={values?.gravidade?.includes(item.id) ? 'contained' : 'outlined'}
                                onClick={() => {
                                    let hold = [...(values?.gravidade ?? [])];
                                    if (hold.includes(item.id)) {
                                        hold = hold.filter(e => e !== item.id);
                                    } else {
                                        hold.push(item.id);
                                    }
                                    onChange('gravidade', hold);
                                }}
                                key={item.id}
                                style={values?.gravidade?.includes(item.id) ? stylesGlobal.buttonPress : stylesGlobal.button}
                            >
                                {item.nome}
                            </Button>
                        );
                    })}
                </Grid>
                <Grid item xs={12}>
                    <div style={stylesGlobal.title}>Setor</div>
                    {filtro.setor.map(item => {
                        return (
                            <Button
                                variant={values?.setor?.includes(item.id) ? 'contained' : 'outlined'}
                                onClick={() => {
                                    let hold = [...(values?.setor ?? [])];
                                    if (hold.includes(item.id)) {
                                        hold = hold.filter(e => e !== item.id);
                                    } else {
                                        hold.push(item.id);
                                    }
                                    onChange('setor', hold);
                                }}
                                key={item.id}
                                style={values?.setor?.includes(item.id) ? stylesGlobal.buttonPress : stylesGlobal.button}
                            >
                                {item.nome}
                            </Button>
                        );
                    })}
                </Grid>
            </Grid>
        </Box>
    );
}
