import React from 'react';
import CounterCard from '../../../@jumbo/components/Common/CounterCard';
import { Box } from '@material-ui/core';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        opacity: 0.8,
        '&:hover': {
            cursor: 'pointer',
            opacity: 0.9,
        },
        '&:active': {
            cursor: 'pointer',
            opacity: 1,
        },
    },
}));

const SimpleDataCard = ({ icon, value, label, colors, setList }) => {
    const classes = useStyles();

    return (
        <Box className={classes.root} onClick={() => setList()}>
            <CounterCard
                icon={icon}
                number={value}
                label={label}
                labelProps={{
                    fontSize: 16,
                    fontWeight: 700,
                }}
                backgroundColor={[`${colors[0]} -18.96%`, `${colors[1]} 108.17%`]}
                gradientDirection="180deg"
            />
        </Box>
    );
};

export default SimpleDataCard;
