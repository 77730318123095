import React, { useState, useEffect } from 'react';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';

import OcurrenceNavbar from '../../../../f5/ocurrences/SharedComponents/OcurrenceNavbar';
import NewOrUpdate from '../shared/NewOrUpdate';

import GravityCustomForm from './GravityCustomForm';
import SharedActions from '../../../../f5/configuration/SharedActions';
import ConfigTablePage from '../../../../f5/configuration/ConfigTablePage';
import sweetAlert from '../../../../f5/utils/sweetAlert';
import configurationService from '../../../../services/f5-services/configurationService';

const Sector = () => {
    const [loading, setLoading] = useState(false);
    const [gravities, setGravities] = useState([]);
    const [newOrUpdate, setNewOrUpdate] = useState({
        mode: 'none',
        id: '',
        name: '',
    });

    const validator = () => {
        if (newOrUpdate.name) {
            return { nome: newOrUpdate.name, id: newOrUpdate.id };
        }

        return false;
    };

    useEffect(() => {
        setLoading(true);
        const getData = async () => {
            const results = await configurationService.getConfigParameter('gravidade');
            setGravities(results.items.map(item => ({ name: item.nome, id: item.id })));
            setLoading(false);
        };
        getData();
    }, [setLoading, setGravities]);

    const resetNewOrUpdate = () => {
        setNewOrUpdate({ mode: 'none' });
    };

    const handleDelete = async id => {
        setLoading(true);
        const deleteResult = await configurationService.deleteConfigParameter(id, 'gravidade');
        const results = await configurationService.getConfigParameter('gravidade');
        setGravities(results.items.map(item => ({ name: item.nome, id: item.id })));
        setLoading(false);
        if (deleteResult.success) {
            sweetAlert('success', 'Sucesso', 'Gravidade foi deletada com sucesso!', () => {
                resetNewOrUpdate();
            });
        } else {
            sweetAlert('error', 'Falha', 'Não foi possível deletar gravidade!', () => {
                resetNewOrUpdate();
            });
        }
    };

    const handleCreate = async data => {
        setLoading(true);
        const addResult = await configurationService.addConfigParameter('gravidade', data);
        const results = await configurationService.getConfigParameter('gravidade');
        setGravities(results.items.map(item => ({ name: item.nome, id: item.id })));
        setLoading(false);
        if (addResult.success) {
            sweetAlert('success', 'Sucesso', 'Gravidade foi adicionada com sucesso!', () => {
                resetNewOrUpdate();
            });
        } else {
            sweetAlert('error', 'Falha', 'Não foi possível adicionar gravidade!', () => {
                resetNewOrUpdate();
            });
        }
    };

    const handleEdit = async data => {
        setLoading(true);
        const addResult = await configurationService.editConfigParameter('gravidade', data);
        const results = await configurationService.getConfigParameter('gravidade');
        setGravities(results.items.map(item => ({ name: item.nome, id: item.id })));
        setLoading(false);
        if (addResult.success) {
            sweetAlert('success', 'Sucesso', 'Gravidade foi editada com sucesso!', () => {
                resetNewOrUpdate();
            });
        } else {
            sweetAlert('error', 'Falha', 'Não foi possível editar gravidade!', () => {
                resetNewOrUpdate();
            });
        }
    };

    const columns = [
        {
            title: 'Nome',
            field: 'name',
        },
        {
            title: 'Ações',
            field: 'actions',
            width: '20%',
            render: rowData => <SharedActions editCallback={() => setNewOrUpdate({ mode: 'edit', name: rowData.name, id: rowData.id })} deleteCallback={() => handleDelete(rowData.id)} />,
        },
    ];

    return (
        <PageContainer>
            <OcurrenceNavbar />
            {newOrUpdate.mode !== 'none' ? (
                <NewOrUpdate newOrUpdate={newOrUpdate} setNewOrUpdate={setNewOrUpdate} handleCreate={handleCreate} handleEdit={handleEdit} loading={loading} validator={validator} addTitle="Adicionar nova gravidade" editTitle="Editar gravidade">
                    <GravityCustomForm newOrUpdate={newOrUpdate} setNewOrUpdate={setNewOrUpdate} />
                </NewOrUpdate>
            ) : (
                <ConfigTablePage loading={loading} data={gravities} columns={columns} title={'Gravidadees'} newCallback={() => setNewOrUpdate({ mode: 'add' })} />
            )}
        </PageContainer>
    );
};

export default Sector;
