import React from 'react';
import { Paper, Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
// import BlockIcon from '@material-ui/icons/Block'; // TODO: Depende do backend para saber se é sucesso ou não a solução, adaptando o ícone de acordo. Ver: FinishOcurrence.js

const useStyles = makeStyles(theme => ({
    paperRoot: { height: '100%' },
    cardRoot: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    textGrey: {
        color: 'rgba(0, 0, 0, 0.5)',
    },
}));

const RecursoCard = ({ movement }) => {
    const classes = useStyles();

    return (
        <Paper className={classes.paperRoot}>
            <Box className={classes.cardRoot} py={2} px={4}>
                <Box>
                    <Box display="flex" alignItems="center" my={2}>
                        <CheckCircleOutlineIcon fontSize="small" />
                        <Box ml={2} fontWeight={500}>
                            Recurso
                        </Box>
                    </Box>
                    <Box mb={2}>
                        <Divider />
                    </Box>
                </Box>
                <Box mb={2} display="flex" alignItems="center">
                    <Box fontWeight={700} fontSize={14}>
                        Mensagem do usuário:
                    </Box>
                </Box>
                <Box ml={2} mb={2}>
                    {movement.descricao}
                </Box>
                <Box mb={2} display="flex" alignItems="center">
                    <Box fontWeight={700} fontSize={14}>
                        Observação interna:
                    </Box>
                    <Box ml={2}>{movement.observacao}</Box>
                </Box>
            </Box>
        </Paper>
    );
};

export default RecursoCard;
