import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { checkCpf, checkCnpj } from '../../../redux/actions/f5-actions/ocurrences-create';
import { Box, Button, Checkbox, Divider } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import InputMask from 'react-input-mask';
import PersonFields from './RequesterComponents/PersonFields';
import CompanyFields from './RequesterComponents/CompanyFields';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    radioLayout: {
        display: 'flex',
        justifyContent: 'space-around',
    },
}));

const privacyTypeOptions = ['Aberto', 'Sigilosa'];
const requesterTypeOptions = ['Pessoa Física', 'Pessoa Jurídica'];
const requestTypeOptions = ['Única', 'Coletiva'];

const Requester = ({
    formStatus,
    formFieldReference,
    setColetive,
    createOcurrenceForm,
    handleFormChange,
    validationErrors,
}) => {
    const [ignoreCpf, setIgnoreCpf] = useState(false);
    const dispatch = useDispatch();
    const classes = useStyles();

    const validateMe = field => {
        if (
            validationErrors.checked &&
            validationErrors.step === 'requester' &&
            validationErrors.list.hasOwnProperty(field)
        ) {
            return {
                isError: true,
                message: validationErrors.list[field] === 'required' ? 'Campo requirido.' : 'Campo inválido.',
            };
        } else {
            return { isError: false, message: '' };
        }
    };

    const scrollToPersonRef = useRef(null);

    const executeScroll = () => setTimeout(() => scrollToPersonRef.current.scrollIntoView(), 500);

    const handleRequestType = event => {
        if (event.target.value === 'coletive') {
            setColetive(true);
        } else {
            setColetive(false);
        }

        handleFormChange(event);
    };

    const handleCheck = () => {
        if (createOcurrenceForm.requesterType === 'person') {
            dispatch(checkCpf(createOcurrenceForm.requesterCpfValue));
        } else {
            dispatch(checkCnpj(createOcurrenceForm.requesterCnpjValue));
        }

        executeScroll();
    };

    const getTextField = () => {
        return createOcurrenceForm.requesterType === 'person' ? (
            <InputMask
                mask="999.999.999-99"
                disabled={ignoreCpf}
                value={createOcurrenceForm.requesterCpfValue}
                onChange={handleFormChange}
            >
                <TextField
                    error={validateMe('requesterCpfValue').isError}
                    helperText={validateMe('requesterCpfValue').message}
                    name="requesterCpfValue"
                    fullWidth
                    size="small"
                    id="identifier-search-cpf"
                    label="CPF"
                    variant="outlined"
                />
            </InputMask>
        ) : (
            <InputMask mask="99.999.999/9999-99" value={createOcurrenceForm.requesterCnpjValue} onChange={handleFormChange}>
                <TextField
                    error={validateMe('requesterCnpjValue').isError}
                    helperText={validateMe('requesterCnpjValue').message}
                    name="requesterCnpjValue"
                    fullWidth
                    size="small"
                    id="identifier-search-cnpj"
                    label="CNPJ"
                    variant="outlined"
                />
            </InputMask>
        );
    };

    const formContent = () => {
        if (formStatus === 'checkedCpf' && createOcurrenceForm.requesterType === 'person') {
            return (
                <PersonFields
                    validateMe={validateMe}
                    formFieldReference={formFieldReference}
                    createOcurrenceForm={createOcurrenceForm}
                    handleFormChange={handleFormChange}
                />
            );
        } else if (formStatus === 'checkedCnpj' && createOcurrenceForm.requesterType === 'company') {
            return (
                <Box>
                    <CompanyFields
                        validateMe={validateMe}
                        createOcurrenceForm={createOcurrenceForm}
                        handleFormChange={handleFormChange}
                    />
                    <Box my={4}>
                        <Divider />
                    </Box>
                    <PersonFields
                        validateMe={validateMe}
                        formFieldReference={formFieldReference}
                        createOcurrenceForm={createOcurrenceForm}
                        handleFormChange={handleFormChange}
                    />
                </Box>
            );
        }

        return <React.Fragment />;
    };

    return (
        <Box my={4}>
            <Box mb={2} className={classes.radioLayout}>
                <Box>
                    <FormControl required component="fieldset">
                        <FormLabel component="legend">Tipo de identificação</FormLabel>
                        <RadioGroup
                            aria-label="Tipo de privacidade"
                            name="privacyType"
                            value={createOcurrenceForm.privacyType}
                            onChange={handleFormChange}
                        >
                            <FormControlLabel value="public" control={<Radio />} label={privacyTypeOptions[0]} />
                            <FormControlLabel value="private" control={<Radio />} label={privacyTypeOptions[1]} />
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl required component="fieldset">
                        <FormLabel component="legend">Tipo de solicitante</FormLabel>
                        <RadioGroup
                            aria-label="Tipo de solicitante"
                            name="requesterType"
                            value={createOcurrenceForm.requesterType}
                            onChange={handleFormChange}
                        >
                            <FormControlLabel value="person" control={<Radio />} label={requesterTypeOptions[0]} />
                            <FormControlLabel value="company" control={<Radio />} label={requesterTypeOptions[1]} />
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Box>
                    <FormControl required component="fieldset">
                        <FormLabel component="legend">Tipo de solicitação</FormLabel>
                        <RadioGroup
                            aria-label="Tipo de solicitação"
                            name="requestType"
                            value={createOcurrenceForm.requestType}
                            onChange={handleRequestType}
                        >
                            <FormControlLabel value="unique" control={<Radio />} label={requestTypeOptions[0]} />
                            <FormControlLabel
                                value="coletive"
                                control={<Radio />}
                                label={requestTypeOptions[1]}
                                disabled={
                                    createOcurrenceForm.requesterType === 'company' ||
                                    createOcurrenceForm.privacyType === 'private'
                                }
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Box>
            <Box ref={scrollToPersonRef} mt={8} width="75%" mx="auto" display="flex" alignItems="center">
                {getTextField()}
                <Box ml={2} display="flex" alignItems="center">
                    <Button variant="contained" color="primary" onClick={handleCheck}>
                        {ignoreCpf ? 'Continuar' : 'Verificar'}
                    </Button>
                </Box>
            </Box>
            {createOcurrenceForm.requesterType === 'person' ? (
                <Box width="75%" mx="auto" mb={8} display="flex" alignItems="flex-start">
                    <FormControlLabel
                        control={<Checkbox />}
                        label="Não tem CPF"
                        onChange={() => setIgnoreCpf(!ignoreCpf)}
                        value={ignoreCpf}
                        disabled={createOcurrenceForm.requesterType === 'company'}
                    />
                </Box>
            ) : null}
            {formContent()}
        </Box>
    );
};

export default Requester;
