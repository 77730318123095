import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initializeOcurrencesOverdue } from '../../../redux/actions/f5-actions/ocurrences-list-init';
import { Grid } from '@material-ui/core';
import GridContainer from '../../../@jumbo/components/GridContainer';
import { useLocation } from 'react-router-dom';
import { isValid, isSameDay } from 'date-fns';

import SimpleDataCard from '../SharedComponents/SimpleDataCard';
import OcurrencesDataRender from '../SharedComponents/OcurrencesDataRender';

import ThumbDownIcon from '@material-ui/icons/ThumbDown';

const OverdueOcurrences = () => {
    const [currentList, setCurrentList] = useState('overdue');
    const overdueData = useSelector(({ ocurrences_data }) => ocurrences_data.overdue.caixa);

    const query = new URLSearchParams(useLocation().search);
    const dateQuery = query.get('data');

    const preparedData = pd => {
        if (dateQuery && pd && isValid(new Date(dateQuery))) {
            const dateFilter = new Date(`${dateQuery}T03:00:00`);
            return pd.filter(item => {
                return isSameDay(dateFilter, new Date(item.prazo));
            });
        } else {
            return pd;
        }
    };

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(initializeOcurrencesOverdue());
    }, [dispatch]);

    const ocurrencesData = {
        overdue: {
            icon: <ThumbDownIcon fontSize="large" />,
            label: `Ocorrências Vencidas ${dateQuery ? dateQuery : ''}`,
            colors: ['#b3597b', '#7a1d41'],
            data: overdueData,
        },
    };

    return (
        <GridContainer>
            <Grid item xs={12} sm={6} md={3}>
                <SimpleDataCard
                    label={ocurrencesData.overdue.label}
                    icon={ocurrencesData.overdue.icon}
                    colors={ocurrencesData.overdue.colors}
                    value={preparedData(ocurrencesData.overdue.data) ? preparedData(ocurrencesData.overdue.data).length : 0}
                    setList={() => setCurrentList('overdue')}
                />
            </Grid>
            <Grid item xs={12}>
                <OcurrencesDataRender
                    bgcolor={ocurrencesData[currentList].colors[0]}
                    data={preparedData(ocurrencesData[currentList].data)}
                    label={ocurrencesData[currentList].label}
                    icon={ocurrencesData[currentList].icon}
                />
            </Grid>
        </GridContainer>
    );
};

export default OverdueOcurrences;
