import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectOcurrence } from '../../../../redux/actions/f5-actions/ocurrences-actions';
import ocurrencesActionService from '../../../../services/f5-services/ocurrencesActionService';
import { Box, Button, CircularProgress, TextField } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BlockIcon from '@material-ui/icons/Block';

import { makeStyles } from '@material-ui/core/styles';

import FeedbackAlert from './FeedbackAlert';
import SharedTitle from './SharedTitle';
import SharedDescription from './SharedDescription';
import sweetAlert from '../../../utils/sweetAlert';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    confirmButton: {
        color: 'white',
        backgroundColor: '#21cc37',
        '&:hover': {
            backgroundColor: '#32e33b',
        },
    },
    denyButton: {
        color: 'white',
        backgroundColor: '#d92916',
        '&:hover': {
            backgroundColor: '#f55538',
        },
    },
}));

const FinishOcurrence = ({ ocurrence }) => {
    const [alertOpen, setAlertOpen] = useState(false);
    const [loading, setLoading] = useState({ message: '', status: false });
    const [finishType, setFinishType] = useState('');
    const [ombudsmanPosition, setOmbudsmanPosition] = useState('');
    const [inputFile, setInputFile] = useState('');
    const [inputFileData, setInputFileData] = useState(null);
    const [note, setNote] = useState('');

    const classes = useStyles();
    const dispatch = useDispatch();

    const handleFileChange = event => {
        setInputFileData(event.target.files[0]);
        setInputFile(event.target.files[0].name);
    };

    // TODO: Melhorar validações de formulário
    const validateForm = () => {
        if (!ombudsmanPosition) {
            return false;
        }

        return true;
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            let formData = new FormData();
            if (inputFileData) {
                setLoading({ status: true, message: 'Fazendo upload de arquivo...' });
                formData.append('anexo', inputFileData);
                formData.append('ocorrencia_id', ocurrence.ocorrencia_id);
                formData.append('file_observacao', note);
                await ocurrencesActionService.sendActionFile(formData);
            }

            setLoading({ status: true, message: 'Finalizando ocorrência...' });

            const postData = {
                descricao: ombudsmanPosition,
                observacao: note,
                ocorrencia_id: ocurrence.ocorrencia_id,
            };

            await ocurrencesActionService.sendFinishOcurrence(postData);

            dispatch(selectOcurrence(ocurrence.ocorrencia_id));
            sweetAlert('success', 'Sucesso', 'Ocorrência finalizada com sucesso!', () => {
                setLoading({ status: false, message: '' });
            });
        } else {
            setAlertOpen(true);
        }
    };

    const finishForm = () => {
        return (
            <Box>
                <SharedTitle icon={finishType === 'success' ? <CheckCircleOutlineIcon fontSize="small" /> : <BlockIcon fontSize="small" />} title={finishType === 'success' ? 'Concluir ocorrência solucionada' : 'Concluir ocorrência não solucionada'} />
                <SharedDescription requester={ocurrence.solicitante.nome} date={`${new Date(ocurrence.abertura).toLocaleDateString()} às ${new Date(ocurrence.abertura).toLocaleTimeString()}`} service={ocurrence.servico} protocol={ocurrence.protocolo} />

                <Box mb={4}>
                    <TextField label="Digite a posição do ouvidor (a mesma será exibida ao solicitante)" required multiline minRows={3} variant="outlined" fullWidth value={ombudsmanPosition} onChange={e => setOmbudsmanPosition(e.target.value)} />
                    <Box fontSize={12} fontWeight={500} mt={4}>
                        Opcionais:
                    </Box>
                    <Box display="flex" flexDirection="column" width="100%" mt={2}>
                        <Box mb={4} display="flex" alignItems="center">
                            <Button variant="contained" component="label">
                                Anexar Arquivo
                                <input id="quick-answer-file" type="file" hidden onChange={handleFileChange} accept=".pdf,.doc,.docx,.jpg,.jpeg,.gif,.png,.xls,.xlsx,.csv" />
                            </Button>
                            <Box ml={2} fontWeight={700} fontSize={12}>
                                {inputFile}
                            </Box>
                        </Box>
                        <Box width={'100%'}>
                            <TextField
                                label="Observação interna"
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={note}
                                onChange={e => setNote(e.target.value)}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" mt={2}>
                    <Button variant="outlined" onClick={() => setFinishType('')}>
                        Voltar
                    </Button>
                    <Box mx={2} />
                    <Button classes={{ root: classes.confirmButton }} onClick={handleSubmit}>
                        Confirmar
                    </Button>
                </Box>
            </Box>
        );
    };

    if (loading.status) {
        return (
            <Box className={classes.root} height={500} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <CircularProgress />
                <Box mt={4} fontWeight={700}>
                    {loading.message}
                </Box>
            </Box>
        );
    }

    return (
        <div className={classes.root}>
            <Box display="flex" alignItems="center" mb={4}>
                <CheckIcon fontSize="small" />
                <Box ml={2} className={classes.formTitle}>
                    Responder ao Munícipe
                </Box>
            </Box>
            {finishType ? (
                finishForm()
            ) : (
                <React.Fragment>
                    <Box my={8} fontWeight={500} fontSize={16} textAlign="center">
                        Como deseja finalizar essa ocorrência?
                    </Box>
                    <Box mt={2} px={4} display="flex" justifyContent="center">
                        <Box mx={2}>
                            <Button classes={{ root: classes.confirmButton }} onClick={() => setFinishType('success')}>
                                SOLUCIONADA
                            </Button>
                        </Box>
                        <Box mx={2}>
                            <Button classes={{ root: classes.denyButton }} onClick={() => setFinishType('failed')}>
                                NÃO SOLUCIONADA
                            </Button>
                        </Box>
                    </Box>
                </React.Fragment>
            )}
            <FeedbackAlert message="Por favor, preencha os campos obrigatórios." open={alertOpen} setOpen={setAlertOpen} />
        </div>
    );
};

export default FinishOcurrence;
