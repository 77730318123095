import React from 'react';
import { Box, Paper } from '@material-ui/core';
import GaugeChart from 'react-gauge-chart';
import { stylesGlobal } from './Styles';

interface PainelGeralProps {
    satisfacao: number;
    counter: {
        total: number;
        period: number;
    };
}

export default function PainelGeral({ satisfacao, counter }: PainelGeralProps) {
    return (
        <Paper>
            <Box p={3} textAlign="center">
                <h1 style={stylesGlobal.title}>Pesquisa de Satisfação</h1>
                <GaugeChart
                    style={{ width: '100%', height: '100%' }}
                    id="gauge-chart2"
                    hideText={true}
                    //   nrOfLevels={5}
                    percent={satisfacao}
                    colors={['#ff1000', '#ff9100', '#eeff00', '#6eff00', '#00ff11']}
                    arcWidth={0.4}
                    cornerRadius={0}
                />
                <div>Respostas Totais</div>
                <h1 style={stylesGlobal.title}>{counter.total.toLocaleString()}</h1>
                <div>Respostas no Período</div>
                <h1 style={stylesGlobal.title}>{counter.period.toLocaleString()}</h1>
            </Box>
        </Paper>
    );
}
