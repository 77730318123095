import { makeStyles } from '@material-ui/core';
import { config } from '../../../services/auth/jwt/config';
import { CloudDownload } from '@material-ui/icons';
import React from 'react';

const useStyles = makeStyles(theme => ({
    paperRoot: { height: '100%' },
    cardRoot: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    imgStyle: {
        width: '95%',
        height: '250px',
        objectFit: 'contain',
    },
    fileControl: {
        position: 'absolute',
        bottom: 0,
        width: '95%',
        height: '32px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    controlColor: {
        color: 'white',
    },
    iconButtonRoot: {
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.15)',
        },
    },
}));

export default function AttachmentDisplay({ item, onDowload, display, ...props }) {
    const [loaded, setLoaded] = React.useState(false);
    const classes = useStyles();

    if (item.type === 'imagem' || item.type === 'image') {
        const fileUrl = `${config.appURL}/download/${window.btoa(item.url)}`;
        return (
            <div style={{ display: display ? 'block' : 'none' }}>
                {loaded ? null : (
                    <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>Carregando...</div>
                )}
                <img
                    src={fileUrl}
                    alt="file name"
                    className={classes.imgStyle}
                    onClick={() => onDowload(item.id)}
                    onLoad={() => {
                        setLoaded(true);
                    }}
                />
            </div>
        );
    } else if (item.type === 'video') {
        const fileUrl = `${config.appURL}/download/${window.btoa(item.url)}`;
        return (
            <video width="95%" height="250px" onClick={() => onDowload(item.id)}>
                <source src={fileUrl} type="video/webm" />O seu navegador não suporta a tag de vídeo.
            </video>
        );
    } else {
        return (
            <div
                onClick={() => onDowload(item.id)}
                style={{
                    height: 250,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    cursor: 'pointer',
                }}
            >
                <CloudDownload style={{ fontSize: 100 }} />
            </div>
        );
    }
}
