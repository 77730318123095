import React, { useCallback, useEffect } from 'react';
import axios_instance from '../services/auth/jwt/config';

interface MainContextType {
    loadDashboard: () => void;
    dashboard: any;
}

let MainContext = React.createContext<MainContextType>({
    loadDashboard: () => {},
    dashboard: false,
});

interface ContextProviderProps {
    children: React.ReactNode;
}

export function useMainContext() {
    return React.useContext(MainContext);
}

let links = {
    dashboard: 'ouvidoria/dashboard',
};

export default function ContextProvider({ children, ...props }: ContextProviderProps) {
    const [dashboard, setDashboard] = React.useState<any>(false);

    const loadDashboard = useCallback(async () => {
        try {
            let response = await axios_instance.get(links.dashboard);
            setDashboard(response.data);
        } catch (error) {}
    }, []);

    useEffect(() => {
        loadDashboard();
    }, [loadDashboard]);

    let value = { loadDashboard, dashboard };

    return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
}
