import React from 'react';
import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts';
import { Box } from '@material-ui/core';

const GeneralGraph = ({ data, colors }) => {
    return (
        <ResponsiveContainer className="card-img-bottom overflow-hidden" width="100%" height={75}>
            <AreaChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                <Tooltip
                    labelStyle={{ color: 'black' }}
                    cursor={false}
                    content={data => {
                        return data.payload[0] ? (
                            <Box component="span" p={4} color="#fff">
                                {data.payload[0].payload.value}
                            </Box>
                        ) : null;
                    }}
                    wrapperStyle={{
                        background: '#6200EE',
                        borderRadius: 10,
                        radius: 10,
                        overflow: 'hidden',
                    }}
                />
                <defs>
                    <linearGradient id="color3" x1="0" y1="0" x2="1" y2="0">
                        <stop offset="5%" stopColor="#3f51b5" stopOpacity={1} />
                        <stop offset="95%" stopColor="#1fb6fc" stopOpacity={1} />
                    </linearGradient>
                </defs>
                <Area dataKey="value" strokeWidth={2} stroke={colors.stroke} fill={colors.fill} fillOpacity={1} />
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default GeneralGraph;
