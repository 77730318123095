import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initializeOcurrencesServices } from '../../../redux/actions/f5-actions/ocurrences-list-init';
import { Grid } from '@material-ui/core';
import GridContainer from '../../../@jumbo/components/GridContainer';

import SimpleDataCard from '../SharedComponents/SimpleDataCard';
import OcurrencesDataRender from '../SharedComponents/OcurrencesDataRender';
import { useLocation } from 'react-router-dom';
import FolderIcon from '@material-ui/icons/Folder';
import moment from 'moment';

const ServicesOcurrences = () => {
    const [currentList, setCurrentList] = useState('services');
    const servicesData = useSelector(({ ocurrences_data }) => ocurrences_data.services.caixa);
    const dispatch = useDispatch();
    const params = new URLSearchParams(useLocation().search);

    useEffect(() => {
        dispatch(initializeOcurrencesServices());
    }, [dispatch]);

    useEffect(() => {
        if (params.get('mode')) {
            setCurrentList(params.get('mode'));
        }
    }, []);

    const preparedData = (pd, start = null, end = null) => {
        if (start && pd) {
            if (end) {
                return pd.filter(item => {
                    return moment(item.abertura).isBetween(moment().subtract(end, 'days'), moment().subtract(start, 'days'));
                });
            } else {
                return pd.filter(item => {
                    return moment(item.abertura).isBefore(moment().subtract(start, 'days'));
                });
            }
        } else {
            return pd;
        }
    };


    const ocurrencesData = {
        services: {
            icon: <FolderIcon fontSize="large" />,
            label: 'Solicitações de Serviço',
            colors: ['#4a99ba', '#0c5c7d'],
            data: preparedData(servicesData),
        },
        fiveday: {
            icon: <FolderIcon fontSize="large" />,
            label: 'Pendentes a 5 - 9 Dias',
            colors: ['#4caf50', '#1b5e20'],
            data: preparedData(servicesData, 5, 9),
        },
        tenday: {
            icon: <FolderIcon fontSize="large" />,
            label: 'Pendentes a 10 - 14 Dias',
            colors: ['#db9237', '#b56c10'],
            data: preparedData(servicesData, 10, 14),
        },
        fifteenday: {
            icon: <FolderIcon fontSize="large" />,
            label: 'Pendentes a 15+ Dias',
            colors: ['#db3737', '#b51010'],
            data: preparedData(servicesData, 15),
        },
    };

    return (
        <GridContainer>
            <Grid item xs={12} sm={6} md={3}>
                <SimpleDataCard
                    label={ocurrencesData.services.label}
                    icon={ocurrencesData.services.icon}
                    colors={ocurrencesData.services.colors}
                    value={ocurrencesData.services.data ? ocurrencesData.services.data.length : 0}
                    setList={() => setCurrentList('services')}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <SimpleDataCard
                    label={ocurrencesData.fiveday.label}
                    icon={ocurrencesData.fiveday.icon}
                    colors={ocurrencesData.fiveday.colors}
                    value={ocurrencesData.fiveday.data ? ocurrencesData.fiveday.data.length : 0}
                    setList={() => setCurrentList('fiveday')}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <SimpleDataCard
                    label={ocurrencesData.tenday.label}
                    icon={ocurrencesData.tenday.icon}
                    colors={ocurrencesData.tenday.colors}
                    value={ocurrencesData.tenday.data ? ocurrencesData.tenday.data.length : 0}
                    setList={() => setCurrentList('tenday')}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <SimpleDataCard
                    label={ocurrencesData.fifteenday.label}
                    icon={ocurrencesData.fifteenday.icon}
                    colors={ocurrencesData.fifteenday.colors}
                    value={ocurrencesData.fifteenday.data ? ocurrencesData.fifteenday.data.length : 0}
                    setList={() => setCurrentList('fifteenday')}
                />
            </Grid>
            <Grid item xs={12}>
                <OcurrencesDataRender
                    bgcolor={ocurrencesData[currentList].colors[0]}
                    data={ocurrencesData[currentList]?.data}
                    label={ocurrencesData[currentList].label}
                    icon={ocurrencesData[currentList].icon}
                />
            </Grid>
        </GridContainer>
    );
};

export default ServicesOcurrences;
