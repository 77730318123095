import React, { useState } from 'react';
import { eachDayOfInterval, isSameMonth, startOfMonth, endOfMonth } from 'date-fns';
import GeneralGraph from './GeneralGraph';
import { makeStyles, Paper } from '@material-ui/core';
import { StatisticsCard } from '../../../@jumbo/components/Common';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    cardRoot: {
        color: theme.palette.common.white,
        textShadow: '1px 1px 2px black',
        height: '150px',
    },
    titleRoot: {
        fontSize: 12,
        fontWeight: 400,
        textTransform: 'uppercase',
        letterSpacing: 0.4,
    },
}));

const createCalendarMonth = () => {
    // Monta ordenado de forma crescente os dias do último mês.
    return eachDayOfInterval({
        start: startOfMonth(new Date()),
        end: endOfMonth(new Date()),
    }).map(item => ({
        day: item.getDate(),
        month: item.getMonth(),
        value: 0,
    }));
};

const GeneralStatistics = ({ data, colors, title, page }) => {
    const [shadow, setShadow] = useState(0);

    const classes = useStyles();

    const prepareData = () => {
        let preparedData = createCalendarMonth();
        const today = new Date();

        if (data) {
            data.forEach(item => {
                const itemDate = new Date(item.inicio);
                if (isSameMonth(today, itemDate)) {
                    preparedData = preparedData.map(item => {
                        if (item.month === itemDate.getMonth() && item.day === itemDate.getDate()) {
                            item.value += 1;
                        }
                        return item;
                    });
                }
            });
            return preparedData;
        } else {
            return preparedData;
        }
    };

    const getTotal = () => {
        return prepareData().reduce((acc, item) => acc + item.value, 0);
    };

    const getDetail = () => {
        let ratioValue;

        const preparedData = prepareData();

        const lastDay = preparedData.length;
        const lastDayValue = preparedData[lastDay - 1].value;
        const total = getTotal();

        if (total <= 0) {
            ratioValue = '...';
        } else {
            ratioValue = ((lastDayValue / total) * 100).toFixed(2);
        }

        return `${ratioValue}% hoje`;
    };

    return (
        <NavLink to={page ? page : '/'}>
            <Paper elevation={shadow} onMouseEnter={() => setShadow(8)} onMouseLeave={() => setShadow(0)}>
                <StatisticsCard
                    className={classes.cardRoot}
                    backgroundColor={colors.background}
                    title={title}
                    titleProps={{
                        variant: 'inherit',
                        component: 'h4',
                        className: classes.titleRoot,
                    }}
                    amount={data ? `${getTotal().toString()} esse mês` : '... esse mês'}
                    description={getDetail()}
                >
                    <GeneralGraph data={prepareData()} colors={colors} />
                </StatisticsCard>
            </Paper>
        </NavLink>
    );
};

export default GeneralStatistics;
