import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@material-ui/core/';
import PainelGeral from '../../../f5/dashboard/components/PainelGeral';
import PainelGrafico from '../../../f5/dashboard/components/PainelGrafico';
import PainelFiltros from '../../../f5/dashboard/components/PainelFiltros';
import PainelMapa from '../../../f5/dashboard/components/PainelMapa';
import moment from 'moment';
import axios_instance from '../../../services/auth/jwt/config';

export type Filtro = {
    sexo: Array<{ id: number; nome: string }>;
    faixaEtaria: Array<number>;
    gravidade: Array<{ id: number; nome: string }>;
    setor: Array<{ id: number; nome: string }>;
};

export type ValueFiltro = {
    sexo: Array<number>;
    faixaEtaria: Array<number>;
    gravidade: Array<number>;
    setor: Array<number>;
};

export default function RatingsDashboard() {
    const [abaFiltro, setAbaFiltro] = useState([{ id: 1, nome: 'Filtros 1', values: {} }]);
    const [filtroOpen, setFiltroOpen] = useState(0);
    const [filtro, setFiltro] = useState<Filtro>({
        sexo: [],
        gravidade: [],
        setor: [],
        faixaEtaria: [0, 120],
    });
    const [satisfacao, setSatisfacao] = useState(0.6);
    const [loading, setLoading] = useState(true);
    const [graph, setGraph] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [counter, setCounter] = useState({
        total: 0,
        period: 0,
    });
    const [periodoGrafico, setPeriodoGrafico] = useState({
        periodoLista: ['semana', 'mês', '6 meses', 'ano', '2 anos'],
        periodo: 'mês',
        iniDate: moment()
            .subtract(1, 'M')
            .toDate(),
        lastDate: moment().toDate(),
    });

    useEffect(() => {
        axios_instance.get<{ success: true; data: Filtro }>(`ouvidoria/satisfacao/filtros`).then(res => {
            let r = res.data;
            if (r.success) {
                setFiltro(r.data);
            }
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        let form = {
            periodo: periodoGrafico.periodo,
            start: periodoGrafico.iniDate,
            end: periodoGrafico.lastDate,
            filtros: abaFiltro,
        };
        axios_instance
            .post<{ success: boolean; data: { satisfacao: any; counter: any; graph: any; markers: any } }>(
                `ouvidoria/satisfacao/data`,
                form,
            )
            .then(res => {
                let r = res.data;
                if (r.success) {
                    setSatisfacao(r.data.satisfacao);
                    setCounter(r.data.counter);
                    setGraph(r.data.graph);
                    setMarkers(r.data.markers);
                }
            });
    }, [abaFiltro, periodoGrafico]);

    const handleDateChange = (date: Date, iniLast: boolean) => {
        let hold = { ...periodoGrafico };
        hold.periodo = '';
        if (iniLast) {
            hold.iniDate = date;
            setPeriodoGrafico(hold);
        } else {
            hold.lastDate = date;
            setPeriodoGrafico(hold);
        }
    };

    const handlePeriodoGrafico = (value: string) => {
        let hold = { ...periodoGrafico };
        hold.lastDate = moment().toDate();
        switch (value) {
            case 'semana':
                hold.iniDate = moment()
                    .subtract(1, 'w')
                    .toDate();
                break;
            case 'mês':
                hold.iniDate = moment()
                    .subtract(1, 'M')
                    .toDate();
                break;
            case '6 meses':
                hold.iniDate = moment()
                    .subtract(6, 'M')
                    .toDate();
                break;
            case 'ano':
                hold.iniDate = moment()
                    .subtract(1, 'y')
                    .toDate();
                break;
            case '2 anos':
                hold.iniDate = moment()
                    .subtract(2, 'y')
                    .toDate();
                break;
            default:
                break;
        }
        hold.periodo = value;
        setPeriodoGrafico(hold);
    };

    const removerFiltro = (pos: number) => {
        let hold = [...abaFiltro];
        if (hold.length > 1) {
            hold.splice(pos, 1);
            if (filtroOpen === pos) {
                setFiltroOpen(0);
            }
            setAbaFiltro(hold);
        }
    };

    const addFiltro = () => {
        let hold = [...abaFiltro];
        let id = hold[hold.length - 1].id + 1;
        hold.push({ id: id, nome: 'Filtros ' + id, values: {} });
        setAbaFiltro(hold);
    };

    if (loading) {
        return (
            <div>
                <CircularProgress />
                Carregando...
            </div>
        );
    }

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <PainelGeral satisfacao={satisfacao} counter={counter} />
                </Grid>
                <Grid item xs={10}>
                    <PainelGrafico
                        handleDateChange={handleDateChange}
                        setPeriodo={handlePeriodoGrafico}
                        periodoLista={periodoGrafico.periodoLista}
                        periodo={periodoGrafico.periodo}
                        selectedDate={periodoGrafico}
                        abaFiltro={abaFiltro}
                        graph={graph}
                    />
                </Grid>
                <Grid item xs={5}>
                    <PainelFiltros
                        abaFiltro={abaFiltro}
                        removerFiltro={removerFiltro}
                        addFiltro={addFiltro}
                        onSelectAbaFiltro={setFiltroOpen}
                        filtro={filtro}
                        selectedFiltro={filtroOpen}
                        onUpdateFiltro={setAbaFiltro}
                    />
                </Grid>
                <Grid item xs={7}>
                    <PainelMapa markers={markers} selected={filtroOpen} />
                </Grid>
            </Grid>
        </div>
    );
}
