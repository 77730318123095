import React, { useEffect, useState } from 'react';
import { Box, Dialog, Grid, Paper } from '@material-ui/core';
import GridContainer from '../../../@jumbo/components/GridContainer';
import axios_instance from '../../../services/auth/jwt/config';
import { useHistory } from 'react-router-dom';

export default function LateWarnDialog({ ...props }) {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const history = useHistory();

    useEffect(() => {
        axios_instance
            .get(`ouvidoria/counters/atrasadas`)
            .then(res => {
                let r = res.data;
                if (r.success) {
                    setData(r.data);
                    setOpen(r.show > 0 ? true : false);
                }
            })
            .catch(() => { });
    }, []);

    return (
        <Dialog open={open} maxWidth="sm" fullWidth onClose={() => setOpen(false)}>
            <Box p={6}>
                <Box mb={6} textAlign="center">
                    <h2>Ocorrências sem respostas em:</h2>
                </Box>
                <GridContainer>
                    {data.map(item => (
                        <Grid item xs={4} key={item.id}>
                            <Paper
                                elevation={3}
                                onClick={() => history.push(`/${item.link}`)}
                                style={{ backgroundColor: item.color, cursor: 'pointer' }} //backgroundImage: 'linear-gradient(#4caf50, #1b5e20)',
                            >
                                <Box textAlign="center" p={3} color="white">
                                    <h3>{item.nome}</h3>
                                    <h1>{item.count}</h1>
                                </Box>
                            </Paper>
                        </Grid>
                    ))}
                </GridContainer>
            </Box>
        </Dialog>
    );
}
