import axios from '../auth/jwt/config';

const getDepartmentMeta = async () => {
    try {
        const response = await axios.get('ouvidoria/departamento/self');
        return response.data;
    } catch (error) {
        return {};
    }
};

const getGeneralOcurrencesPieChart = async () => {
    const response = await axios.get('generic/data/ouvidoria/card');
    return response.data;
};

const getDashboardData = async () => {
    const response = await axios.get('ouvidoria/dashboard');
    return response.data;
};

const getDepartments = async () => {
    const response = await axios.get('ouvidoria/graph/status/departamento');
    return response.data;
};

const getServices = async () => {
    const response = await axios.get('ouvidoria/auxiliar/servico');
    return response.data;
};

const getOmbudsOffices = async () => {
    const response = await axios.get('ouvidoria/ouvidorias/list');
    return response.data;
};

const getSearchData = async () => {
    const response = await axios.get('ouvidoria/auxiliar/busca');
    return response.data;
};

const searchOcurrence = async formData => {
    const response = await axios.post('ouvidoria/busca', formData);
    return response.data;
};

const getCommunicationOcurrences = async () => {
    const response = await axios.get('ouvidoria/caixa/comunique');
    return response.data;
};

const getSubdepartmentOcurrences = async (id, phase) => {
    const withPhase = phase ? `/${phase}` : ''; //pendente, solucionado, andamento
    const response = await axios.get(`ouvidoria/caixas/departamento/${id}${withPhase}`);
    return response.data;
};

const getSectorsCounter = async () => {
    const response = await axios.get('ouvidoria/counters/setores');
    return response.data;
};

const getCalendarStamps = async () => {
    const response = await axios.get('ouvidoria/calendar/vencimento');
    return response.data;
};

export default {
    getGeneralOcurrencesPieChart,
    getDashboardData,
    getDepartments,
    getSearchData,
    searchOcurrence,
    getServices,
    getOmbudsOffices,
    getCommunicationOcurrences,
    getSubdepartmentOcurrences,
    getDepartmentMeta,
    getSectorsCounter,
    getCalendarStamps,
};
