import React from 'react';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';
import OcurrenceNavbar from '../../../../f5/ocurrences/SharedComponents/OcurrenceNavbar';
import ServicesOcurrences from '../../../../f5/ocurrences/ServicesOcurrences';

const Services = () => {
    return (
        <PageContainer>
            <OcurrenceNavbar />
            <ServicesOcurrences />
        </PageContainer>
    );
};

export default Services;
