export const CityCenter = { lat: -23.468, lng: -47.738 };
export const CityBorder = [
    { lng: -47.811, lat: -23.488 },
    { lng: -47.811, lat: -23.486 },
    { lng: -47.806, lat: -23.481 },
    { lng: -47.807, lat: -23.471 },
    { lng: -47.803, lat: -23.467 },
    { lng: -47.799, lat: -23.467 },
    { lng: -47.793, lat: -23.47 },
    { lng: -47.793, lat: -23.467 },
    { lng: -47.789, lat: -23.464 },
    { lng: -47.791, lat: -23.462 },
    { lng: -47.79, lat: -23.454 },
    { lng: -47.792, lat: -23.452 },
    { lng: -47.79, lat: -23.446 },
    { lng: -47.784, lat: -23.444 },
    { lng: -47.779, lat: -23.445 },
    { lng: -47.776, lat: -23.441 },
    { lng: -47.77, lat: -23.441 },
    { lng: -47.765, lat: -23.439 },
    { lng: -47.766, lat: -23.437 },
    { lng: -47.777, lat: -23.437 },
    { lng: -47.78, lat: -23.434 },
    { lng: -47.78, lat: -23.428 },
    { lng: -47.772, lat: -23.422 },
    { lng: -47.771, lat: -23.418 },
    { lng: -47.773, lat: -23.417 },
    { lng: -47.774, lat: -23.413 },
    { lng: -47.771, lat: -23.41 },
    { lng: -47.768, lat: -23.41 },
    { lng: -47.766, lat: -23.406 },
    { lng: -47.766, lat: -23.397 },
    { lng: -47.762, lat: -23.394 },
    { lng: -47.754, lat: -23.396 },
    { lng: -47.753, lat: -23.389 },
    { lng: -47.748, lat: -23.387 },
    { lng: -47.744, lat: -23.383 },
    { lng: -47.739, lat: -23.383 },
    { lng: -47.735, lat: -23.385 },
    { lng: -47.731, lat: -23.381 },
    { lng: -47.724, lat: -23.38 },
    { lng: -47.716, lat: -23.383 },
    { lng: -47.715, lat: -23.385 },
    { lng: -47.705, lat: -23.387 },
    { lng: -47.699, lat: -23.394 },
    { lng: -47.696, lat: -23.395 },
    { lng: -47.692, lat: -23.39 },
    { lng: -47.685, lat: -23.388 },
    { lng: -47.68, lat: -23.391 },
    { lng: -47.68, lat: -23.395 },
    { lng: -47.683, lat: -23.401 },
    { lng: -47.681, lat: -23.403 },
    { lng: -47.68, lat: -23.413 },
    { lng: -47.677, lat: -23.42 },
    { lng: -47.669, lat: -23.426 },
    { lng: -47.658, lat: -23.429 },
    { lng: -47.651, lat: -23.428 },
    { lng: -47.649, lat: -23.43 },
    { lng: -47.649, lat: -23.437 },
    { lng: -47.646, lat: -23.44 },
    { lng: -47.646, lat: -23.446 },
    { lng: -47.65, lat: -23.453 },
    { lng: -47.644, lat: -23.456 },
    { lng: -47.644, lat: -23.46 },
    { lng: -47.647, lat: -23.463 },
    { lng: -47.647, lat: -23.467 },
    { lng: -47.656, lat: -23.474 },
    { lng: -47.665, lat: -23.475 },
    { lng: -47.672, lat: -23.478 },
    { lng: -47.678, lat: -23.485 },
    { lng: -47.685, lat: -23.489 },
    { lng: -47.687, lat: -23.493 },
    { lng: -47.692, lat: -23.497 },
    { lng: -47.695, lat: -23.497 },
    { lng: -47.697, lat: -23.505 },
    { lng: -47.699, lat: -23.507 },
    { lng: -47.698, lat: -23.513 },
    { lng: -47.712, lat: -23.525 },
    { lng: -47.714, lat: -23.536 },
    { lng: -47.718, lat: -23.54 },
    { lng: -47.722, lat: -23.54 },
    { lng: -47.727, lat: -23.537 },
    { lng: -47.741, lat: -23.523 },
    { lng: -47.749, lat: -23.512 },
    { lng: -47.757, lat: -23.506 },
    { lng: -47.762, lat: -23.498 },
    { lng: -47.774, lat: -23.494 },
    { lng: -47.781, lat: -23.495 },
    { lng: -47.786, lat: -23.494 },
    { lng: -47.786, lat: -23.497 },
    { lng: -47.789, lat: -23.501 },
    { lng: -47.8, lat: -23.502 },
    { lng: -47.806, lat: -23.498 },
    { lng: -47.806, lat: -23.495 },
    { lng: -47.81, lat: -23.492 },
    { lng: -47.811, lat: -23.488 },
];
