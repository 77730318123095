import React from 'react';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';

import PendingOcurrences from '../../../../f5/ocurrences/PendingOcurrences';
import OcurrenceNavbar from '../../../../f5/ocurrences/SharedComponents/OcurrenceNavbar';

const Pending = () => {
    return (
        <PageContainer>
            <OcurrenceNavbar />
            <PendingOcurrences />
        </PageContainer>
    );
};

export default Pending;
