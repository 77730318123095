import React, { useState, useEffect } from 'react';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';

import OcurrenceNavbar from '../../../../f5/ocurrences/SharedComponents/OcurrenceNavbar';
import ConfigTablePage from '../../../../f5/configuration/ConfigTablePage';
import NewOrUpdate from '../shared/NewOrUpdate';

import CategoryCustomForm from './CategoryCustomForm';
import sweetAlert from '../../../../f5/utils/sweetAlert';
import SharedActions from '../../../../f5/configuration/SharedActions';
import configurationService from '../../../../services/f5-services/configurationService';

const Category = () => {
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [newOrUpdate, setNewOrUpdate] = useState({
        mode: 'none',
        id: '',
        name: '',
    });

    const validator = () => {
        if (newOrUpdate.name) {
            return { nome: newOrUpdate.name, id: newOrUpdate.id };
        }

        return false;
    };

    useEffect(() => {
        setLoading(true);
        const getData = async () => {
            const results = await configurationService.getConfigParameter('categoria');
            setCategories(results.items.map(item => ({ name: item.nome, id: item.id })));
            setLoading(false);
        };
        getData();
    }, [setLoading, setCategories]);

    const resetNewOrUpdate = () => {
        setNewOrUpdate({ mode: 'none' });
    };

    const handleDelete = async id => {
        setLoading(true);
        const deleteResult = await configurationService.deleteConfigParameter(id, 'categoria');
        const results = await configurationService.getConfigParameter('categoria');
        setCategories(results.items.map(item => ({ name: item.nome, id: item.id })));
        setLoading(false);
        if (deleteResult.success) {
            sweetAlert('success', 'Sucesso', 'Categoria foi deletada com sucesso!', () => {
                resetNewOrUpdate();
            });
        } else {
            sweetAlert('error', 'Falha', 'Não foi possível deletar categoria!', () => {
                resetNewOrUpdate();
            });
        }
    };

    const handleCreate = async data => {
        setLoading(true);
        const addResult = await configurationService.addConfigParameter('categoria', data);
        const results = await configurationService.getConfigParameter('categoria');
        setCategories(results.items.map(item => ({ name: item.nome, id: item.id })));
        setLoading(false);
        if (addResult.success) {
            sweetAlert('success', 'Sucesso', 'Categoria foi adicionada com sucesso!', () => {
                resetNewOrUpdate();
            });
        } else {
            sweetAlert('error', 'Falha', 'Não foi possível adicionar categoria!', () => {
                resetNewOrUpdate();
            });
        }
    };

    const handleEdit = async data => {
        setLoading(true);
        const addResult = await configurationService.editConfigParameter('categoria', data);
        const results = await configurationService.getConfigParameter('categoria');
        setCategories(results.items.map(item => ({ name: item.nome, id: item.id })));
        setLoading(false);
        if (addResult.success) {
            sweetAlert('success', 'Sucesso', 'Categoria foi editada com sucesso!', () => {
                resetNewOrUpdate();
            });
        } else {
            sweetAlert('error', 'Falha', 'Não foi possível editar categoria!', () => {
                resetNewOrUpdate();
            });
        }
    };

    const columns = [
        {
            title: 'Nome',
            field: 'name',
        },
        {
            title: 'Ações',
            field: 'actions',
            width: '20%',
            render: rowData => (
                <SharedActions
                    editCallback={() => setNewOrUpdate({ mode: 'edit', name: rowData.name, id: rowData.id })}
                    deleteCallback={() => handleDelete(rowData.id)}
                />
            ),
        },
    ];

    return (
        <PageContainer>
            <OcurrenceNavbar />
            {newOrUpdate.mode !== 'none' ? (
                <NewOrUpdate
                    newOrUpdate={newOrUpdate}
                    setNewOrUpdate={setNewOrUpdate}
                    handleCreate={handleCreate}
                    handleEdit={handleEdit}
                    loading={loading}
                    validator={validator}
                    addTitle="Adicionar nova categoria"
                    editTitle="Editar categoria">
                    <CategoryCustomForm newOrUpdate={newOrUpdate} setNewOrUpdate={setNewOrUpdate} />
                </NewOrUpdate>
            ) : (
                <ConfigTablePage
                    loading={loading}
                    data={categories}
                    columns={columns}
                    title={'Categorias'}
                    newCallback={() => setNewOrUpdate({ mode: 'add' })}
                />
            )}
        </PageContainer>
    );
};

export default Category;
