import React, { useState, useEffect } from 'react';
import {
  Paper,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  CircularProgress,
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchTable from './SearchTable';
import InputMask from 'react-input-mask';
import generalService from '../../services/f5-services/generalService';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
  },
  formControl: {
    width: '100%',
  },
  searchInputStyle: {
    backgroundColor: 'rgba(100, 100, 100, 0.05)',
  },
  searchButton: {
    marginLeft: theme.spacing(4),
    color: 'white',
    backgroundColor: '#16e03b',
    '&:hover': {
      backgroundColor: '#31f545',
    },
  },
}));

// TODO: Ao usar o search no appbar, ele coloca o valor no campo, mas não chama o handleSubmit() automaticamente.
// Mudar para que quando uma pesquisa seja feita no header, o handler seja chamado. Talvez precise por o handler no useCallback para usá-lo em um efeito reativo, ou algo do tipo.
const SearchPage = ({ searchData, departments, currentSearch, currentType }) => {
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [searchType, setSearchType] = useState(currentType ? currentType : 'generic');
  const [department, setDepartment] = useState('all');
  const [category, setCategory] = useState('');
  const [gravity, setGravity] = useState('');
  const [entry, setEntry] = useState('');
  const [sector, setSector] = useState('');
  const [description, setDescription] = useState('');
  const [searchValue, setSearchValue] = useState(currentSearch ? currentSearch : '');

  const classes = useStyles();

  useEffect(() => {
    if (currentSearch) {
      setSearchValue(currentSearch);
    }
  }, [currentSearch, setSearchValue]);

  const handleSubmit = async () => {
    if (searchValue || searchType === 'generic') {
      setLoading(true);
      const formData = {
        atributo: {
          categoria: category ? category : 0,
          entrada: entry ? entry : 0,
          gravidade: gravity ? gravity : 0,
          setor: sector ? sector : 0,
          descricao: description,
        },
        modo: searchType,
        valor: searchValue,
        departamento: department, // TODO: Implementar filtragem de departamento no backend, atualmente não há funcionalidade.
      };

      const results = await generalService.searchOcurrence(formData);
      setSearchResult(results.ocorrencias);
      setLoading(false);
      setSearchValue('');
    }
  };

  const handleTypeChange = e => {
    setSearchType(e.target.value);
    setSearchValue('');
  };

  const loader = () => {
    return (
      <Box width="100%" height={150} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <CircularProgress />
        <Box mt={4} fontWeight={500}>
          Pesquisando dados...
        </Box>
      </Box>
    );
  };

  return (
    <Box display="flex" flexDirection="column">
      <Paper classes={{ root: classes.root }}>
        <Box display="flex" width="100%" flexDirection="column">
          <Box display="flex" alignItems="center" mb={8} mt={2}>
            <SearchIcon />
            <Box ml={2} fontWeight={700}>
              Pesquisar Ocorrências
            </Box>
          </Box>
          <Box px={8} mb={8} display="flex" alignItems="center">
            {searchType === 'created_at' || searchType === 'prazo' ? (
              <InputMask mask="99/99/9999" value={searchValue} onChange={e => setSearchValue(e.target.value)}>
                <TextField
                  id="filled-basic"
                  label="Sua busca"
                  variant="filled"
                  fullWidth
                  InputProps={{
                    classes: { root: classes.searchInputStyle },
                  }}
                />
              </InputMask>
            ) : (
              <TextField
                id="filled-basic"
                label="Sua busca"
                variant="filled"
                fullWidth
                InputProps={{
                  classes: { root: classes.searchInputStyle },
                }}
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
              />
            )}
            <Button
              variant="contained"
              classes={{ root: classes.searchButton }}
              startIcon={<SearchIcon />}
              onClick={handleSubmit}>
              buscar
            </Button>
          </Box>
          <Box display="flex" px={4} mb={4}>
            <Box width="50%" px={8}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="search-type-label">Modo de pesquisa</InputLabel>
                <Select
                  labelId="search-type-label"
                  id="search-type"
                  value={searchType}
                  onChange={handleTypeChange}
                  label="Modo de pesquisa">
                  <MenuItem value={'generic'}>Todos os registros</MenuItem>
                  <MenuItem value={'protocol'}>Protocolo</MenuItem>
                  <MenuItem value={'name'}>Nome</MenuItem>
                  <MenuItem value={'cpf'}>CPF</MenuItem>
                  <MenuItem value={'address'}>Endereço</MenuItem>
                  <MenuItem value={'created_at'}>Data de Abertura</MenuItem>
                  <MenuItem value={'prazo'}>Data de Vencimento</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box width={'50%'} px={8}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="search-type-label">Departamento</InputLabel>
                <Select
                  labelId="search-type-label"
                  id="search-type"
                  value={department}
                  onChange={e => setDepartment(e.target.value)}
                  label="Departamento">
                  <MenuItem value={'all'}>Todos os Departamentos</MenuItem>
                  {departments.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box px={8} mb={4}>
            <Accordion elevation={3}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Box display="flex" alignItems="center">
                  <FilterListIcon fontSize="small" />
                  <Box ml={2} fontWeight={700} fontSize={12}>
                    Busca avançada
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box display="flex" flexDirection="column" width="100%">
                  <Box width="100%" px={4}>
                    <TextField
                      label="Descrição"
                      multiline
                      rows={2}
                      variant="outlined"
                      fullWidth
                      value={description}
                      onChange={e => setDescription(e.target.value)}
                    />
                  </Box>
                  <Box fontWeight={700} my={4} fontSize={12}>
                    Outros Filtros:
                  </Box>
                  <Box display="flex" mb={4}>
                    <Box width="50%" px={4}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="category-filter-label">Categoria</InputLabel>
                        <Select
                          labelId="category-filter-label"
                          id="category-filter"
                          value={category}
                          onChange={e => setCategory(e.target.value)}
                          label="Categoria">
                          {searchData.categoria.map(item => (
                            <MenuItem value={item.id} key={item.id}>
                              {item.nome}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box width="50%" px={4}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="gravity-filter-label">Gravidade</InputLabel>
                        <Select
                          labelId="gravity-filter-label"
                          id="gravity-filter"
                          value={gravity}
                          onChange={e => setGravity(e.target.value)}
                          label="Gravidade">
                          {searchData.gravidade.map(item => (
                            <MenuItem value={item.id} key={item.id}>
                              {item.nome}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box display="flex" mb={4}>
                    <Box width="50%" px={4}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="entry-filter-label">Entrada</InputLabel>
                        <Select
                          labelId="entry-filter-label"
                          id="entry-filter"
                          value={entry}
                          onChange={e => setEntry(e.target.value)}
                          label="Entrada">
                          {searchData.entrada.map(item => (
                            <MenuItem value={item.id} key={item.id}>
                              {item.nome}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box width="50%" px={4}>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="sector-filter-label">Setor</InputLabel>
                        <Select
                          labelId="sector-filter-label"
                          id="sector-filter"
                          value={sector}
                          onChange={e => setSector(e.target.value)}
                          label="Setor">
                          {searchData.setor.map(item => (
                            <MenuItem value={item.id} key={item.id}>
                              {item.nome}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Paper>
      <Box my={4}>
        <SearchTable data={searchResult} loader={loader} loading={loading} />
      </Box>
    </Box>
  );
};

export default SearchPage;
