import React, { useState, useEffect } from 'react';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';

import OcurrenceNavbar from '../../../../f5/ocurrences/SharedComponents/OcurrenceNavbar';
import NewOrUpdate from '../shared/NewOrUpdate';

import SubjectCustomForm from './SubjectCustomForm';
import ConfigTablePage from '../../../../f5/configuration/ConfigTablePage';
import SharedActions from '../../../../f5/configuration/SharedActions';
import sweetAlert from '../../../../f5/utils/sweetAlert';
import configurationService from '../../../../services/f5-services/configurationService';

const Subject = () => {
    const [loading, setLoading] = useState(false);
    const [subjects, setSubjects] = useState([]);
    const [themes, setThemes] = useState([]);
    const [newOrUpdate, setNewOrUpdate] = useState({
        mode: 'none',
        id: '',
        name: '',
        theme: '',
        themeId: '',
    });

    const validator = () => {
        if (newOrUpdate.name && newOrUpdate.themeId) {
            return {
                nome: newOrUpdate.name,
                id: newOrUpdate.id,
                ouv__tema_id: newOrUpdate.themeId,
            };
        }

        return false;
    };

    useEffect(() => {
        setLoading(true);
        const getData = async () => {
            const results = await configurationService.getConfigParameter('assunto');
            setSubjects(
                results.items.map(item => ({
                    name: item.nome,
                    id: item.id,
                    theme: item.tema.nome,
                    themeId: item.tema.id,
                })),
            );
            setThemes(results.fills.tema);
            setLoading(false);
        };
        getData();
    }, [setLoading, setSubjects]);

    const resetNewOrUpdate = () => {
        setNewOrUpdate({ mode: 'none' });
    };

    const handleDelete = async id => {
        setLoading(true);
        const deleteResult = await configurationService.deleteConfigParameter(id, 'assunto');
        const results = await configurationService.getConfigParameter('assunto');
        setSubjects(
            results.items.map(item => ({
                name: item.nome,
                id: item.id,
                theme: item.tema.nome,
                themeId: item.tema.id,
            })),
        );
        setLoading(false);
        if (deleteResult.success) {
            sweetAlert('success', 'Sucesso', 'Assunto foi deletada com sucesso!', () => {
                resetNewOrUpdate();
            });
        } else {
            sweetAlert('error', 'Falha', 'Não foi possível deletar assunto!', () => {
                resetNewOrUpdate();
            });
        }
    };

    const handleCreate = async data => {
        setLoading(true);
        const addResult = await configurationService.addConfigParameter('assunto', data);
        const results = await configurationService.getConfigParameter('assunto');
        setSubjects(
            results.items.map(item => ({
                name: item.nome,
                id: item.id,
                theme: item.tema.nome,
                themeId: item.tema.id,
            })),
        );
        setLoading(false);
        if (addResult.success) {
            sweetAlert('success', 'Sucesso', 'Assunto foi adicionada com sucesso!', () => {
                resetNewOrUpdate();
            });
        } else {
            sweetAlert('error', 'Falha', 'Não foi possível adicionar assunto!', () => {
                resetNewOrUpdate();
            });
        }
    };

    const handleEdit = async data => {
        setLoading(true);
        const addResult = await configurationService.editConfigParameter('assunto', data);
        const results = await configurationService.getConfigParameter('assunto');
        setSubjects(
            results.items.map(item => ({
                name: item.nome,
                id: item.id,
                theme: item.tema.nome,
                themeId: item.tema.id,
            })),
        );
        setLoading(false);
        if (addResult.success) {
            sweetAlert('success', 'Sucesso', 'Assunto foi editada com sucesso!', () => {
                resetNewOrUpdate();
            });
        } else {
            sweetAlert('error', 'Falha', 'Não foi possível editar assunto!', () => {
                resetNewOrUpdate();
            });
        }
    };

    const columns = [
        {
            title: 'Nome',
            field: 'name',
        },
        {
            title: 'Tema',
            field: 'theme',
        },
        {
            title: 'Ações',
            field: 'actions',
            width: '20%',
            render: rowData => (
                <SharedActions
                    editCallback={() =>
                        setNewOrUpdate({
                            mode: 'edit',
                            name: rowData.name,
                            id: rowData.id,
                            themeId: rowData.themeId,
                        })
                    }
                    deleteCallback={() => handleDelete(rowData.id)}
                />
            ),
        },
    ];

    return (
        <PageContainer>
            <OcurrenceNavbar />
            {newOrUpdate.mode !== 'none' ? (
                <NewOrUpdate
                    newOrUpdate={newOrUpdate}
                    setNewOrUpdate={setNewOrUpdate}
                    handleCreate={handleCreate}
                    handleEdit={handleEdit}
                    loading={loading}
                    validator={validator}
                    addTitle="Adicionar novo assunto"
                    editTitle="Editar assunto">
                    <SubjectCustomForm newOrUpdate={newOrUpdate} setNewOrUpdate={setNewOrUpdate} themes={themes} />
                </NewOrUpdate>
            ) : (
                <ConfigTablePage
                    loading={loading}
                    data={subjects}
                    columns={columns}
                    title={'Assuntos'}
                    newCallback={() => setNewOrUpdate({ mode: 'add' })}
                />
            )}
        </PageContainer>
    );
};

export default Subject;
