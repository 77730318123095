import React from 'react';
import { Paper, Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import ReorderIcon from '@material-ui/icons/Reorder';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MaterialTable from '@material-table/core';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  paperRoot: { height: '100%' },
  cardRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  textGrey: {
    color: 'rgba(0, 0, 0, 0.5)',
  },
}));

export default function GroupCard({ groups, ...props }) {
  const classes = useStyles();
  let history = useHistory();

  const changeRoute = id => {
    history.push(`/ocorrencias/ver/${id}`);
  };

  return (
    <Paper className={classes.paperRoot}>
      <Box className={classes.cardRoot} py={2} px={4}>
        <Box>
          <Box display="flex" alignItems="center" my={2}>
            <ReorderIcon fontSize="small" />
            <Box ml={2} fontWeight={500}>
              Ocorrências agrupadas
            </Box>
          </Box>
          <Box mb={2}>
            <Divider />
          </Box>
        </Box>
        <MaterialTable
          columns={[
            { title: 'Protocolo', field: 'protocolo' },
            { title: 'Descrição', field: 'descricao' },
          ]}
          data={groups}
          title=""
          components={{
            Container: props => <div {...props} />,
          }}
          options={{
            actionsColumnIndex: -1,
            search: false,
          }}
          localization={{
            body: {
              emptyDataSourceMessage: 'Nenhum registro para exibir',
            },
          }}
          actions={[
            {
              icon: () => <VisibilityIcon />,
              tooltip: 'Visualizar',
              onClick: (_event, rowData) => {
                changeRoute(rowData.id);
              },
            },
          ]}
        />
      </Box>
    </Paper>
  );
}
