import React from 'react';
import { Paper, Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

import EmailIcon from '@material-ui/icons/Email';

const useStyles = makeStyles(theme => ({
  paperRoot: { height: '100%' },
  cardRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  textGrey: {
    color: 'rgba(0, 0, 0, 0.5)',
  },
}));

const RequestCard = ({ protocol, theme, subject, service, description }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paperRoot}>
      <Box className={classes.cardRoot} py={2} px={4}>
        <Box>
          <Box display="flex" alignItems="center" my={2}>
            <EmailIcon fontSize="small" />
            <Box ml={2} fontWeight={500}>
              Solicitação
            </Box>
          </Box>
          <Box mb={2}>
            <Divider />
          </Box>
        </Box>
        <Box mb={2}>
          <Box fontWeight={700} fontSize={14} mb={1}>
            Protocolo:
          </Box>
          <Box>{protocol}</Box>
        </Box>
        <Box mb={2}>
          <Box fontWeight={700} fontSize={14} mb={1}>
            Assunto:
          </Box>
          <Box display="flex">
            <Box fontWeight={400} className={classes.textGrey}>
              {theme} /
            </Box>
            <Box fontWeight={500} className={classes.textGrey} ml={1}>
              {subject} /
            </Box>
            <Box fontWeight={500} ml={1}>
              {service}
            </Box>
          </Box>
        </Box>
        <Box mb={2}>
          <Box fontWeight={700} fontSize={14} mb={1}>
            Descrição:
          </Box>
          <Box>
            {description.split('<br />').map((item, index) => (
              <Box key={index}>{item}</Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default RequestCard;
