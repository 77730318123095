import React, { forwardRef } from 'react';
import MaterialTable from '@material-table/core';
import { shortName } from '../../utils/generalUtils';
import { Box } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
//import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import TextWrapper from './TextWrapper';
import DataChip from './DataChip';
import ViewOcurrenceAction from './DataTableActions/ViewOcurrenceAction';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const columns = [
    {
        title: 'Ações',
        field: 'actions',
        render: rowData => <ViewOcurrenceAction id={rowData.ocorrencia_id} />,
        width: '5%',
    },
    {
        title: 'Protocolo',
        field: 'protocolo',
        render: rowData => <Box fontSize={12}>{rowData.protocolo}</Box>,
        width: '10%',
    },
    {
        title: 'Tipo',
        field: 'grupo',
        render: rowData => {
            const groupLabel = rowData.coletivo ? 'Coletiva' : 'Individual';
            const groupColor = rowData.coletivo ? '#bf0f52' : '#677fd6';
            return <DataChip label={groupLabel} color={groupColor} />;
        },
    },
    {
        title: 'Situação',
        field: 'passo_descricao',
        render: rowData => <DataChip label={rowData.passo_descricao} color={'#08c93f'} />,
    },
    {
        title: 'Categoria',
        field: 'categoria',
        render: rowData => <TextWrapper text={rowData.categoria} />,
    },
    {
        title: 'Solicitante',
        field: 'solicitante.nome',
        searchable: true,
        render: rowData => <TextWrapper text={shortName(rowData.solicitante.nome)} />,
    },
    {
        title: 'Assunto',
        field: 'assunto',
        render: rowData => <TextWrapper text={rowData.assunto} />,
    },
    {
        title: 'Secretaria',
        field: 'destinatario_nome',
        render: rowData => <Box fontSize={12}>{rowData.destinatario_nome}</Box>,
    },
    {
        title: 'Data de entrada',
        field: 'abertura',
        type: 'date',
        render: rowData => (
            <Box fontSize={12}>
                {new Date(rowData.abertura).toLocaleDateString()} às {new Date(rowData.abertura).toLocaleTimeString()}
            </Box>
        ),
        defaultSort: 'desc',
    },
    {
        title: 'Prazo',
        field: 'prazo',
        type: 'date',
        render: rowData => (
            <Box
                fontSize={12}
                bgcolor={rowData.prazo && new Date(rowData.prazo) < new Date() ? '#bf0f52' : null}
                borderRadius={20}
                textAlign="center"
                p={0.5}
                color={rowData.prazo && new Date(rowData.prazo) < new Date() ? '#fff' : null}
            >
                {rowData.prazo ? new Date(rowData.prazo).toLocaleDateString() : 'Não Definido'}
            </Box>
        ),
    },
];

const OcurrencesDataTable = ({ data, extraColumns = [] }) => {
    //const query = new URLSearchParams(useLocation().search);
    let history = useHistory();

    const changeRoute = id => {
        history.push(`/ocorrencias/ver/${id}`);
    };

    const handleRowClick = (event, rowData) => {
        changeRoute(rowData.ocorrencia_id);
    };

    let finalColumns = [...columns, ...extraColumns];

    return (
        <Box maxWidth="100%">
            <MaterialTable
                onRowClick={handleRowClick}
                localization={{
                    toolbar: {
                        searchTooltip: 'Filtrar',
                        searchPlaceholder: 'Filtrar',
                    },
                    body: {
                        emptyDataSourceMessage: 'Nenhum dado encontrado.',
                    },
                }}
                icons={tableIcons}
                columns={finalColumns}
                data={data.map(item => ({ ...item, id: item.ocorrencia_id }))}
                title={
                    <Box ml={2} display="flex" alignItems="center">
                        <ListIcon fontSize="small" />
                        <Box ml={2} fontWeight={700}>
                            Lista de Ocorrências
                        </Box>
                    </Box>
                }
                options={{
                    exportButton: true,
                    pageSizeOptions: [],
                    tableLayout: 'fixed',
                    headerStyle: {
                        fontWeight: 700,
                        fontSize: 12,
                    },
                }}
            />
        </Box>
    );
};

export default OcurrencesDataTable;
