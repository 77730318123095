import generalService from '../../../services/f5-services/generalService';
import { INIT_OCURRENCES_MAP, CHANGE_MAP_MODE, INIT_MAP_FILTERS, CHANGE_DEPARTMENT_FILTER, CHANGE_SECTOR_FILTER } from './action-types';

export const initializeOcurrencesMap = () => {
    return async dispatch => {
        const mapOcurrences = await generalService.getDashboardData();
        dispatch({
            type: INIT_OCURRENCES_MAP,
            data: mapOcurrences.ocorrencias,
        });
    };
};

export const initializeOcurrencesFilters = () => {
    return async dispatch => {
        const dashboardInfo = await generalService.getDashboardData();
        dispatch({
            type: INIT_MAP_FILTERS,
            data: dashboardInfo,
        });
    };
};

export const changeMapMode = mode => {
    return {
        type: CHANGE_MAP_MODE,
        payload: mode,
    };
};

export const changeDepartmentFilter = department => {
    return {
        type: CHANGE_DEPARTMENT_FILTER,
        payload: department,
    };
};

export const changeSectorsFilter = sectors => {
    return {
        type: CHANGE_SECTOR_FILTER,
        payload: sectors,
    };
};
