export const stylesGlobal = {
  title: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: '1.2rem',
    fontAlignment: 'center',
    color: 'black',
  },
  textNormal: {
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontAlignment: 'center',
    color: 'black',
  },

  box: {
    backgroundColor: 'white',
  },
  button: {
    color: 'gray',
    backgroundColor: 'white',
    margin: 3,
  },
  buttonPress: {
    color: 'white',
    backgroundColor: '#2596be',
    margin: 3,
  },
};
