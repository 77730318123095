import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initializeOcurrencesPending } from '../../../redux/actions/f5-actions/ocurrences-list-init';
import { Grid } from '@material-ui/core';
import GridContainer from '../../../@jumbo/components/GridContainer';
import { useLocation } from 'react-router-dom';
import SimpleDataCard from '../SharedComponents/SimpleDataCard';
import OcurrencesDataRender from '../SharedComponents/OcurrencesDataRender';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import moment from 'moment';

const PendingOcurrences = () => {
    const [currentList, setCurrentList] = useState('pending');
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const pendingData = useSelector(({ ocurrences_data }) => ocurrences_data.pending.caixa);
    const dispatch = useDispatch();
    const params = new URLSearchParams(useLocation().search);

    useEffect(() => {
        if (params.get('start')) {
            setStart(params.get('start'));
        }
        if (params.get('end')) {
            setEnd(params.get('end'));
        }
    }, [params]);

    const preparedData = pd => {
        if (start && pd) {
            if (end) {
                return pd.filter(item => {
                    return moment(item.abertura).isBetween(moment().subtract(end, 'days'), moment().subtract(start, 'days'));
                });
            } else {
                return pd.filter(item => {
                    return moment(item.abertura).isBefore(moment().subtract(start, 'days'));
                });
            }
        } else {
            return pd;
        }
    };

    useEffect(() => {
        dispatch(initializeOcurrencesPending());
    }, [dispatch]);

    const ocurrencesData = {
        pending: {
            icon: <HelpOutlineIcon fontSize="large" />,
            label: start ? `Ocorrências sem resposta (${start}${end ? `-${end}` : '+'} dias)` : 'Ocorrências Pendentes',
            colors: ['#db9237', '#b56c10'],
            data: pendingData,
        },
    };

    return (
        <GridContainer>
            <Grid item xs={12} sm={6} md={3}>
                <SimpleDataCard
                    label={ocurrencesData.pending.label}
                    icon={ocurrencesData.pending.icon}
                    colors={ocurrencesData.pending.colors}
                    value={preparedData(ocurrencesData.pending.data) ? preparedData(ocurrencesData.pending.data).length : 0}
                    setList={() => setCurrentList('pending')}
                />
            </Grid>
            <Grid item xs={12}>
                <OcurrencesDataRender
                    bgcolor={ocurrencesData[currentList].colors[0]}
                    data={preparedData(ocurrencesData[currentList].data)}
                    label={ocurrencesData[currentList].label}
                    icon={ocurrencesData[currentList].icon}
                />
            </Grid>
        </GridContainer>
    );
};

export default PendingOcurrences;
