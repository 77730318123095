import React, { useState } from 'react';
import { eachMonthOfInterval, subYears, differenceInYears } from 'date-fns';
import GeneralGraph from './GeneralGraph';
import { makeStyles, Paper } from '@material-ui/core';
import { StatisticsCard } from '../../../@jumbo/components/Common';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    cardRoot: {
        color: theme.palette.common.white,
        textShadow: '1px 1px 2px black',
        height: '150px',
    },
    titleRoot: {
        fontSize: 12,
        fontWeight: 400,
        textTransform: 'uppercase',
        letterSpacing: 0.4,
    },
}));

const createCalendarYear = f => {
    // Monta ordenado de forma crescente os meses do último ano, ou se for full, cria anos desde 2000 (Teoricamente pegando todos os anos, uma vez que o app não existia nessa época)
    return eachMonthOfInterval({
        start: f ? new Date('2000-01-01') : subYears(new Date(), 1),
        end: new Date(),
    }).map(item => ({
        month: item.getMonth(),
        year: item.getFullYear(),
        value: 0,
    }));
};

const GeneralStatisticsFull = ({ data, colors, title, page }) => {
    const [shadow, setShadow] = useState(0);

    const classes = useStyles();

    const prepareData = f => {
        let preparedData = createCalendarYear(f);
        if (data) {
            data.forEach(item => {
                const itemDate = new Date(item.inicio);
                if (!f) {
                    if (differenceInYears(new Date(), itemDate) <= 1) {
                        preparedData = preparedData.map(item => {
                            if (item.month === itemDate.getMonth() && item.year === itemDate.getFullYear()) {
                                item.value += 1;
                            }
                            return item;
                        });
                    }
                } else {
                    preparedData = preparedData.map(item => {
                        if (item.month === itemDate.getMonth() && item.year === itemDate.getFullYear()) {
                            item.value += 1;
                        }
                        return item;
                    });
                }
            });
            return preparedData;
        } else {
            return preparedData;
        }
    };

    const getTotal = full => {
        return prepareData(full).reduce((acc, item) => acc + item.value, 0);
    };

    const getDetail = () => {
        let ratioValue;

        const preparedData = prepareData();

        const lastMonth = preparedData.length;
        const lastMonthValue = preparedData[lastMonth - 1].value;
        const total = getTotal();

        if (total <= 0) {
            ratioValue = '...';
        } else {
            ratioValue = ((lastMonthValue / total) * 100).toFixed(2);
        }

        return `${ratioValue}% esse mês`;
    };

    return (
        <NavLink to={page ? page : '/'} className={classes.linkRoot}>
            <Paper elevation={shadow} onMouseEnter={() => setShadow(8)} onMouseLeave={() => setShadow(0)}>
                <StatisticsCard
                    className={classes.cardRoot}
                    backgroundColor={colors.background}
                    title={title}
                    titleProps={{
                        variant: 'inherit',
                        component: 'h4',
                        className: classes.titleRoot,
                    }}
                    amount={data ? `${getTotal(true).toString()} total` : '... total'}
                    description={getDetail()}
                >
                    <GeneralGraph data={prepareData()} colors={colors} />
                </StatisticsCard>
            </Paper>
        </NavLink>
    );
};

export default GeneralStatisticsFull;
